<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="400"
    persistent
  >
    <v-card dense tile>
      <v-card-title>Nota</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row>
            <!-- <v-col cols="12">
              <autocomplete
                :items="toke"
                filterby="nama"
                kolom="nama"
              ></autocomplete
            ></v-col> -->
            <v-col cols="12">
              <v-autocomplete
                v-model="dataNota.toke"
                auto-select-first
                autofocus
                :items="toke"
                item-text="nama"
                item-value="ID"
                label="Toke"
                ref="toke"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Keterangan"
                required
                v-model="dataNota.keterangan"
              >
              </v-text-field>
            </v-col>
            <v-btn color="blue" dark type="submit" :disabled="tombolOff"
              >Simpan</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click="tutup()">Batal</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
// import autocomplete from "./autocomplete.vue";
import axios from "axios";
export default {
  props: {
    notaDialog: Boolean,
    transaksi: Object,
    isEdit: { type: Boolean, default: false },
    dataNota: {
      type: Object,
      default: function () {
        return { ID: 0, toke: 0, tanggal: "", id_admin: 0, keterangan: "" };
      },
    },
    subFolder: { type: Boolean, default: false },
    idFolder: { type: Number, default: 0 },
  },
  // props: ["notaDialog", "toke", "transaksi","isEdit"],
  // components: { autocomplete },
  computed: {
    dialog: {
      get() {
        return this.notaDialog;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    notaDialog() {
      this.kondisikan();
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      tombolOff: false,
      toke: [],
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kirim() {
      this.$refs.toke.focus();
      if (this.valid) {
        this.tombolOff = true;
        this.simpan();
        if (this.isEdit) {
          let uri = "/nota/" + this.dataNota.ID;
          axios
            .put(uri, {
              toke: this.dataNota.toke,
              keterangan: this.dataNota.keterangan,
            })
            .then((response) => {
              if (response.data.status) {
                this.$emit("bukaNota", response.data.data);
                this.$emit("simpan");
                this.$noty.success(response.data.pesan);
              } else {
                this.$noty.error(response.data.pesan);
                console.log("error");
              }
              this.tutup();
              this.tombolOff = false;
            })
            .catch((error) => {
              this.$noty.error(error.Error);
              console.log(error);
              this.tombolOff = false;
            });
        } else {
          let uri = "/nota";
          axios
            .post(uri, this.dataNota)
            .then((response) => {
              if (response.status == 200) {
                this.$emit("bukaNota", response.data.data);
                this.$emit("simpan");
                this.$noty.success(response.data.pesan);
              } else {
                this.$noty.error(response.data.pesan);
              }
              this.tutup();
              this.tombolOff = false;
            })
            .catch((error) => {
              this.$noty.error(error.Error);
              console.log(error);
              this.tombolOff = false;
            });
        }
      }
    },
    simpan() {},
    kondisikan() {
      let uri = "/tokeAktif";
      axios
        .get(uri)
        .then((res) => {
          this.toke = res.data;
        })
        .catch((e) => {
          this.$noty.success(e);
        });
      if (this.isEdit) {
        console.log("ini proses update");
        console.log(this.dataNota);
      } else {
        console.log(this.dataNota);
        if (this.transaksi) {
          this.dataNota.keterangan = "";
          this.dataNota.toke = 0;
          this.dataNota.tanggal = this.transaksi.tanggal;
          this.dataNota.id_admin = this.transaksi.akun.id;
          this.dataNota.id_cair = this.transaksi.id;
          if (this.subFolder) {
            this.dataNota.id_nota = this.idFolder;
            this.dataNota.jenis = 2;
          } else {
            this.dataNota.id_nota = 0;
            this.dataNota.jenis = 0;
          }
        }
      }
    },
  },
};
</script>
