<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div>
          <v-card :loading="loading">
            <v-card-title>
              <h3>List Bank dan Kasir</h3>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                dense
              ></v-text-field>
            </v-card-title>
            <v-card-text>
              <v-row justify="center"
                ><v-col cols="12" md="4" sm="4">
                  <v-date-picker v-model="tanggal"></v-date-picker></v-col
                ><v-col cols="12" md="4" sm="4">
                  <v-card class="mx-auto" tile>
                    <v-list rounded dense>
                      <v-subheader>Kasir</v-subheader>
                      <v-list-item-group color="primary">
                        <v-list-item
                          v-for="(item, i) in kasir"
                          :key="i"
                          :to="'/banklm/' + item.id"
                        >
                          <v-list-item-icon>
                            <v-icon>mdi-cash</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title
                              >{{ item.nama
                              }}<v-list-item-subtitle
                                >{{
                                  (item.modal_sum_masuk - item.modal_sum_keluar)
                                    | rupiah
                                }}
                              </v-list-item-subtitle></v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card> </v-col
                ><v-col cols="12" md="4" sm="4">
                  <v-card class="mx-auto" tile>
                    <v-list rounded dense>
                      <v-subheader>Bank</v-subheader>
                      <v-list-item-group color="primary">
                        <v-list-item
                          v-for="(item, i) in bank"
                          :key="i"
                          :to="'/bank/' + item.id"
                        >
                          <v-list-item-icon>
                            <v-icon>mdi-bank</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title
                              >{{ item.nama
                              }}<v-list-item-subtitle
                                >{{
                                  (item.modal_sum_masuk - item.modal_sum_keluar)
                                    | rupiah
                                }}
                              </v-list-item-subtitle></v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                </v-col></v-row
              >
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      loading: false,
      search: "",
      kasir: [],
      akun: [],
      bank: [],
      tabel: [],
      tanggal: moment().format("YYYY-MM-DD"),
    };
  },
  watch: {
    tanggal() {
      console.log(this.tanggal);
      this.inisialisasi();
    },
  },
  computed: {},
  mounted() {
    this.inisialisasi();
  },
  methods: {
    inisialisasi() {
      this.loading = true;
      let url = `/akun/saldoTanggal/${this.tanggal}`;
      axios.get(url).then((response) => {
        this.akun = _.values(response.data);
        console.log(this.akun);
        this.pisahkan();
        this.loading = false;
      });
    },

    pisahkan() {
      let jenismodal = ["modal", "admin", "anggota"];
      this.kasir = this.akun.filter(function (x) {
        return jenismodal.indexOf(x.jenis) !== -1;
      });
      this.bank = this.akun.filter(function (x) {
        return x.jenis == "bank";
      });
    },
    buka(id) {
      let uri = "/kasir/" + id + "/terbaru";
      let idCair = 0;
      axios.get(uri).then((response) => {
        if (response.data > 0) {
          idCair = response.data;
        }
        this.$router.push("/transaksi/" + id + "/" + idCair);
      });
    },
  },
};
</script>
