<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialogSaldo"
    width="400"
    persistent
  >
    <v-card dense tile>
      <v-card-title>Transaksi Baru</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Saldo Fakta"
                required
                :rules="[
                  (v) => v <= 10000000000 || 'Terlalu besar',
                  (v) => !isNaN(parseFloat(v)) || 'harus di isi angka',
                ]"
                v-model="saldoFakta"
                type="number"
              ></v-text-field>
            </v-col>
            <v-btn
              color="blue"
              dark
              type="submit"
              :loading="loading"
              :disabled="loading"
              >Simpan</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click="tutup()">Batal</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: ["dialogSaldo", "id", "saldo"],
  computed: {
    dialog: {
      get() {
        return this.dialogSaldo;
      },
      set() {
        this.$emit("tutup");
        this.saldoKirim = 0;
      },
    },
    saldoFakta: {
      get() {
        return this.saldo;
      },
      set(value) {
        this.transaksi.saldo = value;
      },
    },
  },
  watch: {},
  mounted() {},
  data() {
    return {
      transaksi: { saldo: 0 },
      menu1: false,
      valid: false,
      loading: false,
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kirim() {
      if (this.valid) {
        this.loading = true;
        let uri = "/transaksi/edit/" + this.id + "/saldo";
        console.log(this.transaksi);
        axios.put(uri, this.transaksi).then((response) => {
          if (response.data.status) {
            this.$noty.success("Data berhasil disimpan");
            this.$emit("lanjutkan", response.data.data);
            this.tutup();
            this.loading = false;
            console.log(response.data);
          }
        });
      }
    },
    simpan() {},
    kondisikan() {},
  },
};
</script>
