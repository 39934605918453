<template>
  <v-row class="ma-2"
    ><v-col cols="12" md="3">
      <v-card class="elevation-1">
        <v-toolbar dense color="red" dark>
          <v-toolbar-title>Pabrik</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="pemicuGrup = true">
            <v-icon>mdi-factory</v-icon> </v-btn
          ><v-btn
            icon
            @click="
              id_pabrik = 0;
              pemicuPabrik = true;
            "
          >
            <v-icon>mdi-plus-thick</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="judulPabrik"
          :items="pabrik"
          :loading="loadingPabrik"
          hide-default-header
          @page-count="pageCount = $event"
          :page.sync="page"
          v-model="pabrikTerpilih"
          :search="search"
          hide-default-footer
          dense
        >
          <template v-slot:item="{ item }">
            <tr
              :class="
                pabrikTerpilih.indexOf(item.id) > -1
                  ? 'primary  white--text font-weight-bold'
                  : ''
              "
              @click="rowClicked(item)"
            >
              <td>{{ item.pabrik }}</td>
              <td>
                <v-btn class="mx-2" icon x-small @click="editP(item)"
                  ><v-icon> mdi-pencil </v-icon></v-btn
                >
                <v-btn
                  class="mx-2"
                  icon
                  color="red"
                  x-small
                  @click="hapusP(item)"
                  :disabled="loadingHapus"
                  ><v-icon> mdi-trash-can </v-icon></v-btn
                >
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-pagination v-model="page" :length="pageCount"></v-pagination>
      </v-card> </v-col
    ><v-col cols="12" md="9">
      <v-card color="grey lighten-4"
        ><v-toolbar dense color="pink" dark>
          <v-toolbar-title>Nama DO</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="baru">
            <v-icon>mdi-plus-thick</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <v-text-field
            v-model="searchDeo"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="judulDeo"
            :items="deo"
            :items-per-page="8"
            :loading="loadingDeo"
            :search="searchDeo"
            v-model="deoterpilih"
            class="elevation-1"
            dense
          >
            <template v-slot:item.bank="{ item }">
              <span>{{ item.bank.nama }}</span>
            </template>

            <template v-slot:item.opsi="{ item }">
              <v-btn
                class="mx-1"
                icon
                x-small
                @click="edit(item)"
                :disabled="loadingHapus"
                ><v-icon> mdi-pencil </v-icon></v-btn
              >
              <v-btn
                class="mx-1"
                icon
                x-small
                color="green"
                @click="bukaTon(item)"
                ><v-icon> mdi-open-in-new </v-icon></v-btn
              >
              <v-btn
                class="mx-1"
                icon
                color="red"
                x-small
                @click="hapus(item)"
                :disabled="loadingHapus"
                ><v-icon> mdi-trash-can </v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <form-deo
        :id="id_do"
        @tutup="pemicuDO = false"
        :pemicu="pemicuDO"
        :pabrik="itemPabrik"
        :kasir="kasir"
        @lanjutkan="refresh"
      ></form-deo>
      <form-pabrik
        :id="id_pabrik"
        :pemicu="pemicuPabrik"
        @lanjutkan="refreshP"
        @tutup="pemicuPabrik = false"
      ></form-pabrik>
      <tonase
        :id="id_do"
        :pemicu="pemicuTonase"
        @tutup="pemicuTonase = false"
      ></tonase>
      <grup :pemicu="pemicuGrup" @tutup="pemicuGrup = false"></grup>
      <v-col cols="12"> </v-col>
    </v-col>
  </v-row>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import formDeo from "./do.vue";
import formPabrik from "./pabrik.vue";
import grup from "./grup.vue";
import tonase from "./tonse.vue";
export default {
  components: {
    formDeo,
    formPabrik,
    grup,
    tonase,
  },
  computed: {
    lastPage() {
      return Math.ceil(this.deo.length / 8);
    },
  },
  data() {
    return {
      search: "",
      searchDeo: "",
      pabrik: [],
      loadingPabrik: false,
      pabrikTerpilih: [],
      deo: [],
      tbDeo: [],
      loadingDeo: false,
      deoterpilih: [],
      loadingHapus: false,
      judulPabrik: [
        {
          text: "Nama Pabrik",
          align: "start",
          filterable: true,
          value: "pabrik",
        },
        {
          text: "aksi",
          align: "right",
          value: "opsi",
        },
      ],
      judulDeo: [
        {
          text: "Nama",
          value: "do",
        },
        {
          text: "Fee",
          value: "fee",
        },
        {
          text: "pph",
          value: "pph",
        },
        {
          text: "ppn",
          value: "ppn",
        },
        {
          text: "Bank",
          value: "bank",
        },
        {
          text: "Opsi",
          value: "opsi",
          align: "right",
        },
      ],
      pemicu: false,
      id_do: 0,
      pemicuTon: false,
      pemicuDO: false,
      pemicuPabrik: false,
      pemicuTonase: false,
      id_pabrik: 0,
      itemPabrik: {},
      page: 1,
      pageCount: 0,
      pemicuGrup: false,
      kasir: [],
    };
  },
  mounted() {
    this.inisialisasi();
  },
  watch: {},
  methods: {
    inisialisasi() {
      this.loading = true;
      let url = "/pabrik";
      axios.get(url).then((response) => {
        axios.get("/akunjurnal/kasir").then((res) => {
          this.kasir = res.data;
        });
        this.pabrik = _.values(response.data);
        this.loadingPabrik = false;
      });
    },
    inisialisasiDo(id) {
      this.loadingDeo = true;
      let url = `/pabrik/deo/${id}`;
      axios.get(url).then((response) => {
        this.deo = _.values(response.data);
        this.loadingDeo = false;
        console.log(this.deo);
      });
    },

    tampilDeo() {
      this.inisialisasiDo(this.pabrikTerpilih[0]);
    },
    bukaTon(item) {
      this.id_do = item.id;
      this.pemicuTonase = true;
    },
    rowClicked(item) {
      this.pabrikTerpilih = [item.id];
      this.itemPabrik = item;
      this.tampilDeo();
    },
    refresh() {
      this.inisialisasi();
      this.tampilDeo();
    },
    refreshP() {
      this.inisialisasi();
    },
    hapus(item) {
      this.loadingHapus = true;
      let uri = "/deo/" + item.id;
      axios.delete(uri).then((response) => {
        this.$noty.success(response.data.pesan);
        this.loadingHapus = false;
        console.log(item.id);
        console.log(this.deo);
        this.tampilDeo();
      });
    },
    hapusP(item) {
      this.loadingHapus = true;
      let uri = "/pabrik/" + item.id;
      axios.delete(uri).then((response) => {
        this.$noty.success(response.data.pesan);
        this.loadingHapus = false;
        this.refreshP();
      });
    },
    lanjutkan() {
      this.tampilDeo();
    },
    tutup() {
      this.pemicu = false;
    },
    baru() {
      if (this.pabrikTerpilih[0] >> 0) {
        this.pemicuDO = true;
        this.id_do = 0;
      } else {
        this.$noty.error("Pilih pabrik dulu");
        console.log("pabrik haurs di pilih");
        console.log(this.pabrikTerpilih);
      }
    },
    edit(item) {
      this.id_do = item.id;
      this.pemicuDO = true;
    },
    editP(item) {
      this.id_pabrik = item.id;
      this.pemicuPabrik = true;
    },
  },
};
</script>
