<template>
  <v-card>
    <v-card-title>
      <v-btn color="green" fab small dark @click="bukaBaru()"
        ><v-icon>mdi-plus</v-icon></v-btn
      >
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="tabel"
      :items-per-page="5"
      dense
      mobile-breakpoint="300"
      :loading="loading"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
        prevIcon: 'mdi-arrow-left-drop-circle-outline',
        nextIcon: 'mdi-arrow-right-drop-circle-outline',
      }"
    >
      <template v-slot:item.tanggal="{ item }">
        <span>{{ item.tanggal | formatDate }}</span>
      </template>
      <template v-slot:item.tanggalawal="{ item }">
        <span
          >{{ item.daritanggal | formatDate }}-{{
            item.sampaitanggal | formatDate
          }}</span
        >
      </template>
      <template v-slot:item.masuk="{ item }">
        <span :class="classEror(item)">{{ item.masuk | rupiah }}</span>
      </template>
      <template v-slot:item.kredit="props">
        <v-edit-dialog
          :return-value.sync="props.item.kredit"
          @save="save(props.item)"
          @cancel="cancel"
          @open="open"
          @close="close"
        >
          {{ props.item.kredit | rupiah }}
          <template v-slot:input>
            <v-text-field
              v-model="nominalNyata"
              label="Edit"
              single-line
              :rules="[
                (v) => v <= 10000000000 || 'Terlalu besar',
                (v) => !isNaN(parseFloat(v)) || 'harus di isi angka',
              ]"
              type="number"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.selisih="{ item }">
        <span>{{
          (parseInt(item.masuk) - parseInt(item.kredit)) | rupiah
        }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn class="mx-2" icon color="blue" x-small @click="buka(item)">
          <v-icon> mdi-pencil </v-icon> </v-btn
        ><v-btn class="mx-2" icon color="blue" x-small @click="bukaSatu(item)">
          <v-icon> mdi-list-status </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <tambah
      :transaksi="transaksi"
      :pemicu="dialogTambah"
      :idcair="idcair"
      @tutup="tutup"
      @insert="insert"
      @update="update"
      :akun="akun"
      :tanggal="tanggal"
    ></tambah>
    <satusatu
      :idTanggal="idcair"
      :pemicu="pemicuSatu"
      @tutup="pemicuSatu = false"
      @update="update"
      :bank="idAkun"
    >
    </satusatu>
  </v-card>
</template>
<script>
import tambah from "./transaksiTambah.vue";
import satusatu from "./satuSatu.vue";
import axios from "axios";
import _ from "lodash";
export default {
  components: { tambah, satusatu },
  props: ["idAkun", "akun", "tanggal", "reload"],
  data() {
    return {
      loading: true,
      search: "",
      tabel: [],
      headers: [
        { text: "Rentang", value: "tanggalawal" },
        { text: "Pabrik", value: "pabrik" },
        { text: "Masuk", value: "masuk" },
        { text: "Fakta", value: "kredit" },
        { text: "Selisih", value: "selisih" },
        { text: "Actions", value: "actions" },
      ],
      pencairan: [],
      pemicuSatu: false,
      tanggalbank: [],
      dialogNew: false,
      dialogTambah: false,
      idcair: 0,
      transaksi: {},
      nominalNyata: 0,
      max25chars: (v) => v.length <= 25 || "Input too long!",
    };
  },
  watch: {
    reload() {
      this.inisialisasi();
      console.log("diganti tanggale");
    },
  },
  computed: {},
  mounted() {
    this.inisialisasi();
  },
  methods: {
    inisialisasi() {
      this.loading = true;
      let url = `/akun/transaksimasuk/${this.idAkun}/${this.tanggal}`;
      axios.get(url).then((response) => {
        this.tabel = _.values(response.data);
        console.log(this.tabel);
        this.loading = false;
      });
    },
    cekNol(nilai) {
      if (nilai) {
        return isNaN(parseInt(nilai, 10)) ? 0 : parseInt(nilai, 10);
      } else {
        return 0;
      }
    },
    buka(item) {
      this.idcair = item.id;
      this.dialogTambah = true;
      this.transaksi = item;
    },
    bukaSatu(item) {
      this.idcair = item.id;
      this.pemicuSatu = true;
    },
    bukaBaru() {
      this.idcair = 0;
      this.dialogTambah = true;
    },
    tutup() {
      this.dialogNew = false;
      this.dialogTambah = false;
    },
    lanjutkan(item) {
      this.dialogNew = false;
      this.tabel.push(item);
      this.dialogTambah = true;
    },
    insert(item) {
      this.idcair = item;
    },
    update() {
      this.inisialisasi();
      this.$emit("update");
    },
    tagihan() {
      this.pemicuTagihan = true;
    },
    save(item) {
      let uri = `/transaksi/edit/${item.idTransaksi}/selisih`;
      let data = {};
      data.kredit = parseInt(this.nominalNyata);
      axios
        .put(uri, data)
        .then((response) => {
          if (response.data.status) {
            this.$noty.success(response.data.messages);
            this.inisialisasi();
          }
        })
        .catch((error) => {
          this.$noty.error(error.response.data.errors[0]);
          console.log(error.response.data.errors);
        });
      this.nominalNyata = 0;
    },
    cancel() {},
    open() {},
    close() {},
    classEror(item) {
      let selisih = parseInt(item.masuk) - parseInt(item.saldo);
      if (selisih > 10 || selisih < -10) {
        return "red";
      }
    },
  },
};
</script>
