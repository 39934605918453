import Vue from "vue";
var numeral = require("numeral");
numeral.register("locale", "rp", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "b",
    trillion: "t",
  },
  ordinal: function (number) {
    return number === 1 ? "er" : "ème";
  },
  currency: {
    symbol: "Rp.",
  },
});

// switch between locales
numeral.locale("rp");

Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("rupiah", function (value) {
  if (value) {
    return numeral(parseInt(value) || 0).format("$0,0");
  } else {
    return numeral(0).format("$0,0");
  }
});
Vue.filter("angka", function (value) {
  if (value) {
    const ang = parseInt(value) || 0;
    return ang == 0 ? 0 : numeral(ang).format("0,0");
  }
});
Vue.filter("angkaKoma", function (value) {
  if (value) {
    return numeral(parseFloat(value) || 0).format("0,0.0");
  }
});

export default {};
