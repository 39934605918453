<template>
  <v-card :loading="loading">
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        dense
      ></v-text-field>
      <v-switch label="tanpa nota" v-model="noNota" class="ml-2"></v-switch>
      <v-btn
        color="orange"
        class="ml-1"
        v-if="!adaYangTerpilih"
        x-small
        dark
        @click="pemicuPindah = true"
        >Pindah Nota</v-btn
      >
      <v-btn
        color="green"
        x-small
        dark
        class="mx-2 my-sm-0 my-2"
        @click="bukaGantiDo"
        v-if="!adaYangTerpilih"
        >Ganti DO</v-btn
      >
      <v-btn
        color="green"
        x-small
        dark
        class="mx-2 my-sm-0 my-2"
        @click="dialogTgl = true"
        v-if="!adaYangTerpilih"
        >Ganti Tanggal</v-btn
      >
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="tonase"
      :search="search"
      :items-per-page="8"
      dense
      show-select
      v-model="terpilih"
      group-by="do.pabrik.pabrik"
      item-key="id"
      mobile-breakpoint="300"
      :footer-props="{
        showFirstLastPage: false,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
        prevIcon: 'mdi-arrow-left-drop-circle-outline',
        nextIcon: 'mdi-arrow-right-drop-circle-outline',
      }"
    >
      <template v-slot:group.header="{ items, isOpen, toggle }">
        <th colspan="11" class="blue lighten-4">
          <v-icon @click="toggle"
            >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
          </v-icon>
          {{ items[0].do.pabrik.pabrik }}
        </th>
      </template>
      <template v-slot:item.tanggaldo="{ item }">
        <span>{{ item.tanggaldo | formatDate }}</span>
      </template>
      <template v-slot:item.tonase="{ item }">
        <span>{{ parseFloat(item.tonase) | formatNumber }}</span>
      </template>
      <template v-slot:item.harga="{ item }">
        <span>{{ parseFloat(item.harga) | formatNumber }}</span>
      </template>
      <template v-slot:item.ops="{ item }">
        <span>{{ parseFloat(item.ops) | formatNumber }}</span>
      </template>
      <template v-slot:item.th="{ item }">
        <span>{{ parseFloat(item.th) | formatNumber }}</span>
      </template>
      <template v-slot:item.c="{ item }">
        <span>{{
          (item.tonase * (parseFloat(item.harga) + parseFloat(item.th || 0)))
            | rupiah
        }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn class="mx-2" icon x-small color="green" @click.stop="edit(item)">
          <v-icon> mdi-pencil </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          icon
          x-small
          color="red"
          @click.stop="hapus(item)"
          :loading="loadingDelet"
          :disabled="loadingDelet"
        >
          <v-icon> mdi-delete-circle-outline </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <tonedit
      :id="idTon"
      :deo="deo"
      :toke="toke"
      :dialogTon="dialogTon"
      @tutup="tutupDialog"
      @update="reload"
    ></tonedit>
    <gantido
      :deo="deo"
      :pabrik="pabrik"
      :dialogDeo="dialogDeo"
      @tutup="dialogDeo = false"
      @update="reload"
      :terpilih="terpilih"
    ></gantido>
    <gantitgl
      :dialogTgl="dialogTgl"
      @tutup="tutupDialog"
      @update="reload"
      :terpilih="terpilih"
    ></gantitgl>
    <pindah
      :pemicu="pemicuPindah"
      @tutup="pemicuPindah = false"
      @simpan="
        pemicuPindah = false;
        inisialisasi();
      "
      :id="id"
      :paket="terpilih"
      :tonase="true"
    ></pindah>
  </v-card>
</template>
<style>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
<script>
import axios from "axios";
import tonedit from "./input/tonaseEdit.vue";
import gantido from "./input/gantiDo.vue";
import gantitgl from "./input/gantiTanggal.vue";
import pindah from "./pindah/listNota.vue";
export default {
  props: ["id", "tonLoad", "toke", "deo", "pabrik"],
  components: {
    tonedit,
    gantido,
    gantitgl,
    pindah,
  },
  computed: {
    adaYangTerpilih() {
      if (this.terpilih.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    console.log("di loading");
    this.inisialisasi();
  },
  watch: {
    noNota() {
      if (this.noNota) {
        this.tanpaNota();
      } else {
        this.inisialisasi();
      }
    },
  },
  data() {
    return {
      tonase: [],
      idTon: 0,
      headers: [
        {
          text: "Pabrik",
          align: "start",
          filterable: true,
          value: "do.pabrik.pabrik",
        },
        { text: "Tanggal", value: "tanggaldo" },
        { text: "Toke", value: "toke.nama" },
        { text: "Supir", value: "nama supir" },
        { text: "Plat", value: "plat" },
        { text: "Tonase", value: "tonase" },
        { text: "Harga", value: "harga" },
        { text: "TH", value: "th" },
        { text: "OPS", value: "ops" },
        { text: "Cair", value: "c" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      search: "",
      terpilih: [],
      dialogTon: false,
      dialogDeo: false,
      dialogTgl: false,
      loadingDelet: false,
      noNota: false,
      pemicuPindah: false,
      loading: false,
    };
  },
  methods: {
    inisialisasi() {
      this.loading = true;
      this.terpilih = [];
      let uri = `/transaksi/${this.id}/tonase`;
      axios
        .get(uri)
        .then((res) => {
          this.tonase = res.data;
          this.loading = false;
        })
        .catch((e) => {
          this.$noty.error(e.message);
          this.loading = false;
        });
    },

    tanpaNota() {
      this.loading = true;
      let uri = `/transaksi/${this.id}/tanpaNota`;
      axios
        .get(uri)
        .then((res) => {
          this.tonase = res.data.tonase;
          console.log(res.data);
          this.loading = false;
        })
        .catch((e) => {
          console.log({ msg: e.message, fungsi: "tanpaNota" });
          this.loading = false;
        });
    },
    edit(item) {
      this.idTon = item.id;
      this.dialogTon = true;
    },
    hapus(item) {
      this.loadingDelet = true;
      let uri = "/tonase/" + item.id;
      axios.delete(uri).then((response) => {
        console.log(response);
        this.$emit("reload");
        this.loadingDelet = false;
      });
    },
    tutupDialog() {
      this.dialogTon = false;
      this.dialogDeo = false;
      this.dialogTgl = false;
    },
    reload() {
      this.inisialisasi();
      this.terpilih = [];
      this.tutupDialog();
    },
    bukaGantiDo() {
      this.dialogDeo = true;
    },
  },
};
</script>
