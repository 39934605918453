<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="400"
    persistent
  >
    <v-card dense tile>
      <v-card-title>Pinjaman</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field label="Supir" required v-model="dataSupir.supir">
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="plat" required v-model="dataSupir.plat">
              </v-text-field>
            </v-col>
            <v-btn color="blue" dark type="submit">Simpan</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click="tutup()">Batal</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: ["toke", "supirDialog"],
  computed: {
    dialog: {
      get() {
        return this.supirDialog;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {},
  mounted() {},
  data() {
    return {
      valid: false,
      dataSupir: {
        supir: "",
        toke: 0,
        plat: "",
      },
    };
  },
  methods: {
    tutup() {
      this.dataSupir = {};
      this.$emit("tutup");
    },
    kirim() {
      if (this.valid) {
        this.simpan();
        this.dataSupir.toke = this.toke.ID;
        let uri = "/supir";
        axios.post(uri, this.dataSupir).then((response) => {
          if (response.data.status) {
            this.$noty.success("Data berhasil disimpan");
            this.$emit("update", this.dataSupir);
            this.tutup();
          }
        });
      }
    },
    simpan() {},
  },
};
</script>
