<template>
  <div>
    <div v-if="notaAktif">
      <isinota
        @tutup="
          notaAktif = false;
          dialogNota = false;
        "
        :dialog="dialogNota"
        :idNota="idNota"
        :toke="toke"
        :deo="deo"
        :pabrik="pabrik"
        @reload="refreshData"
        :loading="loading"
        :cekReload="cekReload"
        :transaksi="transaksi"
        @reloadIsi="bukaNotaBaru"
      ></isinota>
    </div>
    <div v-if="posisi == 'folder'">
      <isiFolder
        @tutup="
          pemicuIsiFolder = false;
          posisi = 'home';
        "
        :dialog="pemicuIsiFolder"
        :idFolder="idNota"
        @reload="refreshData"
        :cekReload="cekReload"
        :transaksi="transaksi"
      ></isiFolder>
    </div>
    <v-card :loading="loading" elevation="3" v-if="posisi == 'home'">
      <!-- <v-card-title>Nota</v-card-title> -->
      <v-card-actions>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          dense
          label="Cari"
          class="mx-2"
        ></v-text-field>
        <v-spacer></v-spacer
        ><v-btn dark @click="buatNota()" :disabled="loading"
          ><v-icon>mdi-file-plus</v-icon></v-btn
        ><v-btn
          color="orange"
          dark
          @click="pemicuFolder = true"
          :disabled="loading"
          ><v-icon>mdi-folder-plus</v-icon></v-btn
        >
      </v-card-actions>
      <v-card-text>
        <v-row>
          <v-col
            v-for="item in notaFilter"
            :key="item.id"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card
              elevation="3"
              @click="bukaFolder(item)"
              v-if="item.jenis == 1"
            >
              <v-card-subtitle class="orange lighten-4"
                ><v-icon>mdi-folder</v-icon>
                <b class="text-uppercase"> {{ item.keterangan }}</b>
              </v-card-subtitle>
              <v-card-text>
                <b class="blue--text">{{ item.total | rupiah }}</b>
              </v-card-text>
            </v-card>
            <v-card
              elevation="3"
              @click="bukaNota(item)"
              v-if="item.jenis == 0"
            >
              <v-card-subtitle class="grey lighten-2" v-if="item.toke"
                ><v-icon>mdi-account</v-icon> {{ item.toke.nama }}
              </v-card-subtitle>
              <v-card-text>
                <b class="text-uppercase">{{ item.keterangan }}</b>
                <br /><b class="blue--text">{{ item.total | rupiah }}</b>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <notabaru
        :toke="toke"
        :notaDialog="notaDialog"
        :transaksi="transaksi"
        @tutup="notaDialog = false"
        @bukaNota="bukaNotaBaru"
      ></notabaru>
      <folder
        :pemicu="pemicuFolder"
        :transaksi="transaksi"
        @tutup="pemicuFolder = false"
        @bukaNota="bukaFolderBaru"
      ></folder>
    </v-card>
  </div>
</template>

<script>
import isinota from "./isiNota.vue";
import notabaru from "./input/notaBaru.vue";
import folder from "./input/folder.vue";
import isiFolder from "./isiFolder.vue";
export default {
  components: {
    isinota,
    notabaru,
    folder,
    isiFolder,
  },
  props: [
    "nota",
    "loading",
    "transaksi",
    "cekReload",
    "toke",
    "pabrik",
    "deo",
    "resetPosisi",
  ],
  data() {
    return {
      menu: [
        { id: 1, title: "Tonase", icon: "mdi-file-table-outline" },
        { id: 2, title: "Pinjam PS", icon: "mdi-account" },
        { id: 3, title: "Pinjam PT", icon: "mdi-account" },
        { id: 4, title: "Bayar PS", icon: "mdi-note" },
        { id: 5, title: "Bayar PT", icon: "mdi-note" },
      ],
      selectedItem: 1,
      dialogNota: false,
      idNota: 0,
      tonase: [],
      tonLoad: false,
      pinjaman: [],
      notaDialog: false,
      loadingData: true,
      cekNotaBaru: false,
      search: "",
      pemicuFolder: false,
      pemicuIsiFolder: false,
      posisi: "home",
      notaAktif: false,
    };
  },
  computed: {
    notaFilter() {
      return this.nota.filter((item) => {
        console.log("cek item");
        console.log(item);
        let toke = item.toke ? item.toke.nama.toLowerCase() : "";
        let keterangan =
          item.keterangan === null ? "" : item.keterangan.toLowerCase();
        return (
          toke.indexOf(this.search.toLowerCase()) > -1 ||
          keterangan.indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
  },
  watch: {
    resetPosisi() {
      this.posisi = "home";
    },
  },
  methods: {
    diklik(item) {
      switch (item.id) {
        case 1:
          console.log("email");
          break;
        case 2:
          console.log("jadi lah");
          break;
        case 3:
          console.log("Logout");
      }
    },
    buatNota() {
      this.notaDialog = true;
      // console.log(this.notaFilter);
    },
    bukaNotaBaru(item) {
      this.$emit("reload", item.ID);
      this.bukaNota(item);
    },
    bukaNota(item) {
      this.idNota = item.ID;
      this.notaAktif = true;
      this.dialogNota = true;
    },
    bukaFolderBaru(item) {
      this.$emit("reload", item.ID);
      this.bukaFolder(item);
    },
    bukaFolder(item) {
      this.idNota = item.ID;
      this.posisi = "folder";
    },
    refreshData(item) {
      console.log("item diterima di listnota:");
      console.log(item);
      this.$emit("reload", item);
    },
    printin() {
      this.$htmlToPaper("printkan");
    },
  },
};
</script>
