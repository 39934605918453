<template>
  <v-container class="py-1 px-1" fluid>
    <v-card>
      <v-toolbar dense color="blue accent-4" dark elevation="-2">
        <v-btn icon large @click="kembali">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>{{ transaksi.nama }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="blue"></v-tabs-slider>
            <v-tab v-for="item in items" :key="item" @click="idTab = item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </template>
        <inputsaldo
          :dialogSaldo="dialogSaldo"
          @tutup="dialogSaldo = false"
          @lanjutkan="getTransaksi"
          :idAkun="id"
          :tanggal="tanggal"
          :saldo="transaksi.saldoF"
        ></inputsaldo>
      </v-toolbar>
      <v-row class="mt-2"
        ><v-col cols="12" md="3" v-if="!$vuetify.breakpoint.smAndDown">
          <div class="mx-auto px-auto">
            <v-date-picker
              elevation="15"
              rounded
              v-model="tanggal"
              :events="listTanggal"
              event-color="blue darken-3"
            ></v-date-picker></div></v-col
        ><v-col cols="12" :md="!$vuetify.breakpoint.smAndDown ? 9 : ''">
          <v-card color="grey lighten-3">
            <v-row class="ma-1" dense>
              <v-col cols="12"
                ><span class="mx-4 text-h5"
                  >{{ tanggal | hari }} | {{ tanggal | formatDate }}</span
                >
                <v-btn icon @click="pemicuDate = true" lg class="mb-2">
                  <v-icon>mdi-calendar</v-icon></v-btn
                ></v-col
              >
              <v-col cols="12" md="9">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <span class="ma-3 text-left">
                          Saldo : {{ transaksi.saldo | rupiah }}</span
                        >
                      </td>
                      <td>
                        <span class="ma-3 text-left">
                          <a class="pa-2 text-bold" @click="dialogSaldo = true"
                            >Saldo Fakta : {{ transaksi.saldoF | rupiah }}</a
                          ></span
                        >
                      </td>
                      <td>
                        <span class="ma-3 text-left"
                          >Selisih: {{ selisih | angka }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
              <v-col cols="12" md="3">
                <v-btn @click="printIn" :disabled="loading" class="mx-2" small
                  ><v-icon>mdi-printer</v-icon></v-btn
                ><v-btn @click="pemicuLog = true" :disabled="loading" small
                  ><v-icon>mdi-printer-search</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-card>
          <v-col cols="12" class="px-0 mb-3">
            <v-dialog
              transition="dialog-bottom-transition"
              v-model="pemicuDate"
              width="500"
              ><v-date-picker
                elevation="15"
                rounded
                v-model="tanggal"
                :events="listTanggal"
                event-color="blue darken-3"
              ></v-date-picker
            ></v-dialog>
            <listnota
              v-if="idTab == 'Transaksi'"
              :nota="notas"
              :loading="loading"
              @reload="getTransaksi()"
              :cekReload="cekReload"
              :idAkun="id"
              :tanggal="tanggal"
            ></listnota>
            <tonase
              v-if="idTab == 'Tonase'"
              :idAkun="id"
              :tanggal="tanggal"
              @reload="getTransaksi()"
            ></tonase>
            <pinjamanT
              v-if="idTab == 'Pinjaman'"
              :idAkun="id"
              :tanggal="tanggal"
              @reload="getTransaksi()"
            >
            </pinjamanT>
          </v-col>
          <rentang
            :pemicu="pemicuTgl"
            @tutup="pemicuTgl = false"
            @simpan="printTgl"
          >
          </rentang>
          <printt
            @tutup="pemicuPrint = false"
            :pemicu="pemicuPrint"
            :tanggalPrint="tanggalPrint"
            :admin="transaksi"
          ></printt>
          <log
            :pemicu="pemicuLog"
            :id="transaksi"
            @tutup="pemicuLog = false"
          ></log>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import listnota from "./listNota.vue";
import tonase from "./tonase.vue";
import inputsaldo from "./input/inputsaldo.vue";
import pinjamanT from "./pinjaman.vue";
import rentang from "../component/popupTgl.vue";
import printt from "./portrait.vue";
import log from "./logPrint.vue";
import moment from "moment";
import axios from "axios";
export default {
  components: {
    listnota,
    tonase,
    inputsaldo,
    pinjamanT,
    rentang,
    printt,
    log,
  },
  computed: {
    selisih() {
      return parseInt(this.transaksi.saldo) - parseInt(this.transaksi.saldoF);
    },
  },
  props: ["id"],
  data() {
    return {
      tanggal: moment().format("YYYY-MM-DD"),
      headers: [{ text: "tanggal", value: "tanggal" }],
      search: "",
      tab: null,
      items: ["Transaksi", "Tonase", "Pinjaman"], // pencairan di banned sementara
      notas: [],
      pinjaman: [],
      transaksi: {},
      loading: true,
      loadingIdtransaksi: false,
      cekReload: false,
      toke: [],
      deo: [],
      pabrik: [],
      idTab: "Transaksi",
      dialogSaldo: false,
      loadingRefresh: false,
      pemicuTgl: false,
      pemicuPrint: false,
      tanggalPrint: [],
      pemicuLog: false,
      listTanggal: [],
      pemicuDate: false,
      reset: false,
    };
  },
  mounted() {
    this.getTransaksi();
  },
  watch: {
    tanggal() {
      this.getTransaksi();
      this.pemicuDate = false;
    },
  },
  methods: {
    getTransaksi() {
      this.loading = true;
      this.nota = [];
      this.transaksi = {};
      console.log(this.id);
      let uri = `/pencairan/kasir/saldo/${this.id}/${this.tanggal}`;
      axios
        .get(uri)
        .then((response) => {
          this.transaksi = response.data;
          this.notas = response.data.nota;
          this.transfer = response.data.transfer;
          console.log(this.transaksi);
          this.listTanggal = this.transaksi.tanggalAll;
          console.log(uri);
          console.log(this.transfer);
          this.transfer.map((x) => {
            x.jurnal.referensi = {
              ID: x.ID,
              toke: { nama: x.toke.nama },
              caraCair: x.caraCair,
              jenis: x.jenis,
            };
            this.notas.push(x.jurnal);
          });
          this.notas.map((x) => {
            x.metode = x.referensi ? x.referensi.caraCair : "cash";
            return x;
          });
          console.log(this.transaksi);
          this.loading = false;
        })
        .catch((e) => {
          this.$noty.error(e.message);
          this.loading = false;
        });
    },
    kembali() {
      this.$router.push("/transaksi");
    },
    printIn() {
      this.pemicuTgl = true;
    },
    printTgl(x) {
      console.log("dipicu");
      console.log(x);
      this.tanggalPrint = x;
      this.IDadmin = this.transaksi.id;
      let id = this.transaksi.id;
      let tgl = this.tanggal;
      let data = {
        akun: id,
        dari: x[0],
        sampai: x[1],
        tanggal: tgl,
        toke: "0",
        jenis: "1",
        uraian: null,
        akun_fee: "0",
      };
      let uri = "/print";
      axios
        .post(uri, data)
        .then((response) => {
          if (response.data.status) {
            this.$noty.success("Data berhasil disimpan");
            this.pemicuPrint = true;
          } else {
            this.$noty.success(response.data.data[0]);
          }
        })
        .catch((e) => {
          this.$noty.error(e.message);
          this.loading = false;
        });
    },
  },
};
</script>
