<template>
  <div>
    <div v-if="notaAktif">
      <isinota
        @tutup="
          notaAktif = false;
          dialogNota = false;
        "
        :dialog="dialogNota"
        :idNota="idNota"
        :idAkun="idAkun"
        :toke="toke"
        :deo="deo"
        :pabrik="pabrik"
        @reload="refreshData"
        :loading="loading"
        :cekReload="cekReload"
        :transaksi="transaksi"
        @reloadIsi="bukaNotaBaru"
      ></isinota>
    </div>
    <div v-if="posisi == 'folder'">
      <isiFolder
        @tutup="
          pemicuIsiFolder = false;
          posisi = 'home';
        "
        :dialog="pemicuIsiFolder"
        :idFolder="idNota"
        @reload="refreshData"
        :cekReload="cekReload"
        :transaksi="transaksi"
      ></isiFolder>
    </div>
    <v-card :loading="loading" elevation="3" v-if="posisi == 'home'">
      <!-- <v-card-title>Nota</v-card-title> -->
      <v-card-actions>
        <v-row no-gutters
          ><v-col cols="12" sm="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              dense
              label="Cari"
              class="mx-2"
            ></v-text-field
          ></v-col>
          <v-spacer></v-spacer
          ><v-col cols="12" sm="7"
            ><v-btn
              dark
              @click="
                idNota = 0;
                notaDialog = true;
              "
              :disabled="loading"
              outlined
              color="green"
              class="mx-1"
              ><v-icon>mdi-file-plus</v-icon
              ><span class="text-caption">Nota</span></v-btn
            ><v-btn
              color="orange"
              @click="
                idNota = 0;

                pemicuPinjaman = true;
              "
              :disabled="loading"
              outlined
              class="ma-1"
              ><v-icon>mdi-plus</v-icon>
              <span class="text-caption">Pinjaman</span></v-btn
            ><v-btn
              color="red"
              dark
              @click="pemicuBayar = true"
              :disabled="loading"
              outlined
              class="ma-1"
              ><v-icon>mdi-checkbox-multiple-marked-circle-outline</v-icon
              ><span class="text-caption">Bayar</span></v-btn
            ><v-btn
              color="blue"
              dark
              outlined
              @click="pemicuPigas = true"
              :disabled="loading"
              class="ma-1"
              ><v-icon>mdi-shoe-sneaker</v-icon
              ><span class="text-caption">Pigas</span></v-btn
            ><v-btn
              color="red"
              dark
              outlined
              @click="
                idNota = 0;
                pemicuRamp = true;
              "
              :disabled="loading"
              class="ma-1"
              ><v-icon>mdi-factory</v-icon
              ><span class="text-caption">Ramp</span></v-btn
            ><v-btn
              color="green"
              dark
              outlined
              @click="
                idNota = 0;
                posisi = 'excel';
                pemicuExcel = true;
              "
              :disabled="loading"
              class="ma-1"
              ><v-icon>mdi-microsoft-excel</v-icon></v-btn
            >
            <v-btn color="blue" @click="insertDraft" class="ma-1" outlined>
              <v-icon class="red--text">mdi-plus</v-icon
              ><span class="text-caption">Draft</span></v-btn
            ><v-btn
              color="blue lighten-5"
              @click="pemicuPindah = true"
              v-if="terpilih.length > 0"
              class="ma-1"
              ><v-icon class="blue--text">mdi-calendar-cursor</v-icon
              ><span class="text-caption blue--text">Pindah</span></v-btn
            ><v-btn
              color="blue lighten-5"
              @click="ubahJenisNota"
              v-if="terpilih.length > 0"
              class="ma-1"
            >
              <v-icon class="red--text">mdi-pencil</v-icon
              ><span class="text-caption blue--text">Ubah</span></v-btn
            ><v-btn
              color="red"
              @click="ubahAkun"
              outlined
              v-if="terpilih.length > 0"
              class="ma-1"
            >
              <v-icon class="red--text">mdi-account-edit</v-icon
              ><span class="text-caption">Akun</span></v-btn
            > </v-col
          ><v-dialog v-model="pemicuPindah" width="350"
            ><v-card :loading="prosesPindah"
              ><v-card-text>
                <v-date-picker rounded v-model="tanggalPindah"></v-date-picker
                ><v-btn class="ma-3" @click="pindah">Simpan</v-btn></v-card-text
              >
            </v-card></v-dialog
          >
        </v-row>
      </v-card-actions>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="notaUpdate"
          :search="search"
          :items-per-page="500"
          show-select
          dense
          v-model="terpilih"
          group-by="metode"
          item-key="ID"
          mobile-breakpoint="300"
          hide-default-footer
          v-show="nota.length > 0"
        >
          <template v-slot:group.header="{ items, isOpen, toggle }">
            <th colspan="6" class="blue lighten-4">
              <v-icon @click="toggle"
                >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
              </v-icon>
              {{ items[0].metode }}
            </th>
          </template>
          <template v-slot:item.uraian="{ item }">
            <span :class="draft(item)">{{
              item.referensi ? item.referensi.keterangan : item.uraian
            }}</span>
          </template>
          <template v-slot:item.pinjam="{ item }">
            <v-badge left :content="item.masuk" :value="item.rekening">
              <span :class="draft(item)">{{ item.pinjam | rupiah }} </span>
            </v-badge>
          </template>

          <template v-slot:item.angsur="{ item }">
            <v-badge left :content="item.keluar" :value="item.rekening">
              <span :class="draft(item)">{{ item.angsur | rupiah }} </span>
            </v-badge>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              class="mx-2"
              icon
              x-small
              color="green"
              @click.stop="bukaNota(item)"
            >
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
            <v-btn
              class="mx-2"
              fab
              icon
              x-small
              color="red"
              :nota="nota"
              @click.stop="hapus(item)"
              :loading="loadingDelet"
              :disabled="loadingDelet"
            >
              <v-icon> mdi-trash-can </v-icon>
            </v-btn>
            <span v-if="bank">
              <v-btn
                v-if="item.rekening"
                class="mx-2"
                icon
                color="red"
                x-small
                @click="hapusLink(item)"
              >
                <v-icon> mdi-link-off </v-icon>
              </v-btn>
              <v-btn
                v-else
                class="mx-2"
                icon
                color="orange"
                x-small
                @click="bukaLink(item)"
              >
                <v-icon> mdi-link </v-icon>
              </v-btn></span
            >
          </template></v-data-table
        >
      </v-card-text>
      <notabaru
        :pemicu="notaDialog"
        :tanggal="tanggal"
        :idAkun="idAkun"
        :id="idNota"
        @tutup="notaDialog = false"
        @bukaNota="bukaNotaBaru"
      ></notabaru>
      <pinjamanMini
        :id="idNota"
        :pemicu="pemicuPinjaman"
        :tanggal="tanggal"
        :idAkun="idAkun"
        :tanpaNota="false"
        @tutup="pemicuPinjaman = false"
        @simpan="refreshData"
      >
      </pinjamanMini>
      <BayarTagihan
        :pemicu="pemicuBayar"
        :id="0"
        :idNota="0"
        :folder="0"
        :idAkun="idAkun"
        :tanggal="tanggal"
        @tutup="pemicuBayar = false"
        @simpan="bukaNotaTagihan"
      >
      </BayarTagihan>
      <notaTagihan
        @tutup="pemicuNotaTagihan = false"
        :pemicu="pemicuNotaTagihan"
        :id="idNota"
        :idAkun="idAkun"
        :tanggal="tanggal"
        @reload="refreshData"
      ></notaTagihan>
      <ubahNota
        @tutup="pemicuUbah = false"
        :pemicu="pemicuUbah"
        :id="selected"
        @next="bukaUbahan"
        @simpan="refreshData"
      ></ubahNota>
      <pigas
        :pemicu="pemicuPigas"
        @tutup="pemicuPigas = false"
        :idNota="0"
        :idAkun="idAkun"
        :tanggal="tanggal"
        @simpan="refreshData"
      ></pigas>
      <ramp
        @tutup="pemicuRamp = false"
        :pemicu="pemicuRamp"
        :id="idNota"
        :idAkun="idAkun"
        :tanggal="tanggal"
        @reload="refreshData"
      ></ramp>
      <draft
        @tutup="pemicuDraft = false"
        :pemicu="pemicuDraft"
        :idAkun="idAkun"
        :tanggal="tanggal"
        :nota="nota"
        @reload="refreshData"
      ></draft>
      <gantiAkun
        @tutup="
          pemicuGantiAkun = false;
          terpilih = [];
        "
        :pemicu="pemicuGantiAkun"
        :data="terpilih"
        @reload="refreshData"
      ></gantiAkun>
    </v-card>
    <excelNota
      v-if="posisi == 'excel'"
      @tutup="
        posisi = 'home';
        pemicuExcel = false;
      "
      :pemicu="pemicuExcel"
      :idAkun="idAkun"
      :tanggal="tanggal"
      @reload="refreshData"
    ></excelNota>
  </div>
</template>

<script>
import isinota from "./isiNota.vue";
import notabaru from "./input/notaBaru.vue";
import isiFolder from "./isiFolder.vue";
import BayarTagihan from "./input/transfer.vue";
import notaTagihan from "./input/bayar/bayarTagihan.vue";
import pinjamanMini from "./input/bayar/pinjamanMini.vue";
import ramp from "../transaksiRam/notaRamp.vue";
import pigas from "./input/bayar/bayarPigas.vue";
import excelNota from "./excelNota.vue";
import ubahNota from "./input/ubahNota.vue";
import draft from "./input/draft.vue";
import gantiAkun from "./pindah/pindahAkun.vue";
import axios from "axios";
export default {
  components: {
    isinota,
    notabaru,
    isiFolder,
    pinjamanMini,
    BayarTagihan,
    notaTagihan,
    pigas,
    ramp,
    excelNota,
    ubahNota,
    draft,
    gantiAkun,
  },
  props: [
    "nota",
    "loading",
    "transaksi",
    "cekReload",
    "toke",
    "pabrik",
    "deo",
    "resetPosisi",
    "tanggal",
    "idAkun",
    "bank",
  ],
  data() {
    return {
      menu: [
        { id: 1, title: "Tonase", icon: "mdi-file-table-outline" },
        { id: 2, title: "Pinjam PS", icon: "mdi-account" },
        { id: 3, title: "Pinjam PT", icon: "mdi-account" },
        { id: 4, title: "Bayar PS", icon: "mdi-note" },
        { id: 5, title: "Bayar PT", icon: "mdi-note" },
      ],
      headers: [
        {
          text: "Toke",
          align: "start",
          filterable: true,
          value: "nama",
        },
        { text: "Uraian", value: "uraian" },
        { text: "Masuk", value: "pinjam" },
        { text: "Keluar", value: "angsur" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      terpilih: [],
      loadingDelet: false,
      pemicuRamp: false,
      buka: {
        0: {
          fungsi: () => {
            this.bukaN();
          },
          icon: "mdi-note-outline",
          jenis: "nota",
          uriHapus: `/pencairan/nota/`,
          warna: "yellow lighten-4",
        },
        1: {
          fungsi: () => {
            this.pemicuPinjaman = true;
          },
          icon: "mdi-note-outline",
          jenis: "nota",
          uriHapus: `/pencairan/nota/`,
          warna: "blue lighten-4",
        },
        3: {
          fungsi: () => {
            this.bukaPencairan();
          },
          icon: "mdi-note-outline",
          jenis: "nota",
          uriHapus: `/nota/`,
          warna: "blue lighten-4",
        },
        4: {
          fungsi: () => {
            this.pemicuNotaTagihan = true;
          },
          icon: "mdi-note-outline",
          jenis: "nota",
          uriHapus: `/pencairan/transfer/`,
          warna: "blue lighten-4",
        },
        5: {
          fungsi: () => {
            this.pemicuRamp = true;
          },
          icon: "mdi-folder",
          jenis: "ramp",
          uriHapus: `/pencairan/nota/`,
          warna: "",
        },
        100: {
          fungsi: () => {
            this.pemicuUbah = true;
          },
          icon: "mdi-folder",
          jenis: "pinjaman",
          uriHapus: `/pinjaman/`,
          warna: "",
        },
      },
      selectedItem: 1,
      dialogNota: false,
      idNota: 0,
      tonase: [],
      tonLoad: false,
      pinjaman: [],
      notaDialog: false,
      loadingData: true,
      cekNotaBaru: false,
      search: "",
      pemicuPinjaman: false,
      pemicuFolder: false,
      pemicuIsiFolder: false,
      posisi: "home",
      notaAktif: false,
      pemicuBayar: false,
      pemicuPigas: false,
      pemicuNotaTagihan: false,
      pemicuPindah: false,
      tanggalPindah: this.tanggal,
      prosesPindah: false,
      pemicuExcel: false,
      pemicuUbah: false,
      pemicuDraft: false,
      pemicuGantiAkun: false,
      selected: [],
    };
  },
  computed: {
    notaUpdate() {
      return this.nota.filter((x) => {
        if (x.referensi) {
          x.nama = x.referensi.toke.nama;
        } else {
          x.nama = x.akuns ? x.akuns.nama : x.toke.nama;
        }
        return x;
      });
    },
  },
  watch: {
    resetPosisi() {
      this.posisi = "home";
    },
    tanggal() {
      console.log("tanggal dirubah");
      this.terpilih = [];
    },
  },
  methods: {
    diklik(item) {
      switch (item.id) {
        case 1:
          console.log("email");
          break;
        case 2:
          console.log("jadi lah");
          break;
        case 3:
          console.log("Logout");
      }
    },
    bukaNotaBaru(item) {
      this.$emit("reload", item.ID);
      this.idNota = item.ID;
      this.notaAktif = true;
      this.dialogNota = true;
    },
    bukaNota(item) {
      if (item.referensi) {
        this.idNota = item.referensi.ID;
        this.selected = [item.referensi.ID];
        this.buka[item.referensi.jenis].fungsi();
        console.log(this.dialogNota);
      }
    },
    bukaN() {
      this.notaAktif = true;
      this.dialogNota = true;
    },
    bukaNotaTagihan(item) {
      this.idNota = item;
      console.log("notaTagihan di buka");
      console.log(item);
      this.pemicuNotaTagihan = true;
      this.$emit("reload", item);
    },
    refreshData(item) {
      console.log("item diterima di listnota:");
      console.log(item);
      this.$emit("reload", item);
    },
    insertDraft() {
      this.pemicuDraft = true;
    },
    pindah() {
      if (this.terpilih.length > 0 && this.prosesPindah == false) {
        this.prosesPindah = true;
        let uri = "/pencairan/nota/pindah";
        let data = {};
        data.array = this.terpilih.map((x) => x.ID);
        data.tanggal = this.tanggalPindah;
        axios
          .post(uri, data)
          .then((res) => {
            if (res.data.status) {
              this.pemicuPindah = false;
              this.$noty.success("berhasil dipindah");
              this.terpilih = [];
              this.prosesPindah = false;
              this.refreshData(res.data);
            }
          })
          .catch((e) => {
            console.log(e.message);
            this.$noty.error(e.message);
            this.prosesPindah = false;
            this.pemicuPindah = false;
          });
      }
    },
    ubahJenisNota() {
      console.log(this.terpilih);
      this.selected = this.terpilih.map((x) => x.referensi.ID);
      console.log(this.idNota);
      this.pemicuUbah = true;
      this.terpilih = [];
    },
    hapus(item) {
      if (confirm("apa kamu yakin mau menghapus?")) {
        if (item.referensi) {
          let idNota = item.referensi.ID;
          const uri = this.buka[item.referensi.jenis].uriHapus + idNota;
          console.log(uri);
          axios.delete(uri).then((res) => {
            if (res.data.status) {
              this.$noty.success("berhasil di hapus");
              this.$emit("reload", item);
            } else {
              this.$noty.error("gagal di hapus");
              console.log(res.data);
            }
          });
        }
      }
    },
    classTabel(item) {
      return this.buka[item.jenis].warna;
    },
    bukaUbahan(item) {
      console.log("ubah nota di eksekusi");
      console.log(item);
      this.idNota = item.id;
      if (item.ubahan == 0) {
        this.notaDialog = true;
      } else {
        this.buka[item.ubahan].fungsi();
      }
      this.$emit("reload", item.id);
    },
    ubahAkun() {
      this.pemicuGantiAkun = true;
    },
    printin() {
      this.$htmlToPaper("printkan");
    },
    draft(item) {
      if (item.referensi) {
        if (item.referensi.jenis == 100) {
          return "red--text";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    bukaLink(item) {
      this.$emit("bukaLink", item);
    },
    hapusLink(item) {
      this.$emit("hapusLink", item);
    },
  },
};
</script>
