<template>
  <div>
    <v-footer dark padless fixed app class="d-print-none">
      <v-card class="flex" flat tile>
        <v-card-text class="py-2 white--text text-center">
          {{ new Date().getFullYear() }} —
          <strong> &copy; Rizki Kurniawan</strong>
        </v-card-text>
      </v-card>
      <!-- <v-bottom-navigation
        v-model="value"
        background-color="blue"
        dark
        shift
        grow
        v-else
      >
        <v-btn v-for="item in items" :key="item.title" :to="item.route">
          <span>{{ item.title }}</span>

          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
      </v-bottom-navigation> -->
    </v-footer>
  </div>
</template>
<script>
export default {
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
    value: "",
  }),
  props: ["items"],
};
</script>
