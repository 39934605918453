<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div>
          <v-card>
            <v-card-title>
              <h3>List Plat mobil</h3>
              <v-btn color="pink" dark @click="print">Print</v-btn>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                dense
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="toke"
              :search="search"
              :items-per-page="10"
              dense
              show-select
              item-key="id"
              mobile-breakpoint="300"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-arrow-left-drop-circle-outline',
                nextIcon: 'mdi-arrow-right-drop-circle-outline',
              }"
              :loading="loading"
            >
              <template v-slot:item.supir.supir="{ item }">
                <div v-for="a in item.supir" :key="a.id">
                  {{ a.supir + ": " + a.plat }}
                </div>
              </template>
              <template v-slot:item.aksi="{ item }">
                <v-btn @click="tambahPlat(item)" color="green" dark small
                  >Tambah</v-btn
                >
                <v-btn @click="tambahPlat(item)" color="red" dark small
                  >Hapus</v-btn
                >
              </template>
            </v-data-table>
          </v-card>
          <inputSupir
            :supirDialog="dialogSupir"
            :toke="tokeTerpilih"
            @tutup="tutupSupir"
            @update="updateTabel"
          ></inputSupir>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import inputSupir from "./supir";
import _ from "lodash";
import axios from "axios";
export default {
  components: { inputSupir },
  data() {
    return {
      loading: true,
      headers: [
        {
          text: "ID",
          align: "start",
          filterable: true,
          value: "ID",
        },
        { text: "Nama", value: "nama" },
        { text: "Regional", value: "regional.regional" },
        { text: "Supir", value: "supir.supir" },
        { text: "Aksi", value: "aksi" },
      ],
      search: "",
      toke: [],
      dialogSupir: false,
      tokeTerpilih: {},
      togPrint: false,
    };
  },
  watch: {},
  computed: {},
  mounted() {
    this.inisialisasi();
  },
  methods: {
    inisialisasi() {
      this.loading = true;
      axios.get("/toke/supir").then((response) => {
        this.toke = _.values(response.data);
        this.loading = false;
        console.log(this.toke);
      });
    },
    tutupSupir() {
      this.dialogSupir = false;
    },
    print() {
      const { href } = this.$router.resolve({
        path: "/print/plat/",
      });
      window.open(href, "_blank");
    },
    updateTabel(data) {
      this.toke
        .find((x) => {
          return x.ID == data.toke;
        })
        .supir.push(data);
      console.log(data);
    },
    tambahPlat(item) {
      this.tokeTerpilih = item;
      this.dialogSupir = true;
    },
  },
};
</script>
