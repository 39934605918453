<template>
  <div>
    <v-row no-gutters
      ><v-col cols="1">
        <download-excel
          class="mx-2"
          :data="tonase"
          :fields="fieldExcell"
          worksheet="My Worksheet"
          :name="filename"
        >
          <v-btn icon color="blue"><v-icon>mdi-table-large</v-icon></v-btn>
        </download-excel>
      </v-col>
      <v-col cols="12" md="6"
        ><v-btn
          color="success"
          small
          @click.prevent="dialogF = true"
          class="ma-2"
        >
          Filter <v-icon right dark> mdi-filter </v-icon>
        </v-btn>
        <v-btn color="success" small @click.prevent="bukaToke" class="ma-2">
          Ganti Toke<v-icon right dark> mdi-account-switch </v-icon>
        </v-btn>
        <v-btn
          color="purple"
          small
          @click.prevent="simpanValidasi"
          class="ma-2"
          dark
        >
          Validasi<v-icon right dark> mdi-account-multiple-check </v-icon>
        </v-btn>
        <v-btn
          color="pink"
          small
          @click.prevent="bukaGantiDo"
          class="ma-2"
          dark
        >
          Ganti do<v-icon right dark> mdi-account-switch </v-icon>
        </v-btn>
        <v-btn
          color="pink"
          small
          @click.prevent="pemicuRekap = true"
          class="ma-2"
          dark
        >
          rekap<v-icon right dark> mdi-account-switch </v-icon>
        </v-btn> </v-col
      ><v-spacer></v-spacer
      ><v-col cols="12" md="2" class="mt-1 mx-3 mx-md-1"
        ><v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          dense
        ></v-text-field
      ></v-col>
      <v-col cols="12" md="2" class="mt-md-1 mt-4 ml-1">
        <v-text-field
          :value="dataTgl.tertulis"
          label="Pilih bulan/tanggal"
          prepend-icon="mdi-calendar"
          readonly
          dense
          @click="menu = true"
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="mb-6">
        <div>
          <v-card>
            <v-data-table
              :headers="headers"
              :items="tonase"
              :search="search"
              :items-per-page="10"
              dense
              show-select
              v-model="terpilih"
              item-key="id"
              mobile-breakpoint="300"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-arrow-left-drop-circle-outline',
                nextIcon: 'mdi-arrow-right-drop-circle-outline',
              }"
              :loading="loading"
            >
              <template v-slot:item.ok="{ item }">
                <v-simple-checkbox
                  v-model="item.ok"
                  disabled
                ></v-simple-checkbox>
              </template>
              <template v-slot:item.tanggaldo="{ item }">
                <span>{{ item.tanggaldo | formatDate }}</span>
              </template>
              <template v-slot:item.tonase="{ item }">
                <span>{{ item.tonase | angka }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  class="mx-2"
                  icon
                  x-small
                  color="blue"
                  @click.stop="editItem(item)"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <template v-slot:item.plat="props">
                <v-edit-dialog
                  :return-value.sync="props.item.plat"
                  @save="save(props.item)"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                >
                  {{ props.item.plat }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.plat"
                      :rules="[max25chars]"
                      label="Edit"
                      single-line
                      counter
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
            </v-data-table>
          </v-card>
          <r-dialog
            :id="idTon"
            :deo="deo"
            :toke="toke"
            :dialogTon="dialogTon"
            @tutup="
              dialogTon = false;
              idTon = 0;
            "
            @update="update"
          ></r-dialog>
        </div>
      </v-col>
      <gantitoke
        @tutup="dialogTo = false"
        :pemicu="dialogTo"
        @aksi="SimpanToke"
        :dataFilter="tabelFilter"
        :toke="toke"
      ></gantitoke>
      <gantido
        :deo="deo"
        :pabrik="pabrik"
        :dialogDeo="dialogDeo"
        @tutup="tutupDialog"
        @update="reload"
        :terpilih="terpilih"
      ></gantido>
      <saringanfull
        @tutup="dialogF = false"
        :pemicu="dialogF"
        :tonase="tonase"
        @simpan="refresh"
        @reset="reset"
      ></saringanfull>
      <rekap
        @tutup="pemicuRekap = false"
        :pemicu="pemicuRekap"
        :tonase="tonase"
      ></rekap>
      <bln :pemicu="menu" @tutup="menu = false" @update="updateBln"></bln>
    </v-row>
  </div>
</template>
<script>
import rDialog from "../Pencairan/input/tonaseEdit.vue";
import saringanfull from "./filterfull";
import bln from "./bln.vue";
import gantitoke from "./gantiToke";
import gantido from "../Pencairan/input/gantiDo.vue";
import rekap from "./rekapTon.vue";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
export default {
  components: {
    rDialog,
    saringanfull,
    bln,
    gantitoke,
    gantido,
    rekap,
  },
  data() {
    return {
      tabel: [],
      dialogTon: false,
      dialogF: false,
      dialogDeo: false,
      idTon: 0,
      loading: true,
      platEdit: "",
      max25chars: (v) => v.length <= 25 || "Input too long!",
      headers: [
        {
          text: "Pabrik",
          align: "start",
          filterable: true,
          value: "do.pabrik.pabrik",
        },
        { text: "Tanggal", value: "tanggaldo" },
        { text: "Toke", value: "toke.nama" },
        { text: "Supir", value: "supir" },
        { text: "Plat", value: "plat" },
        { text: "Tonase", value: "tonase" },
        { text: "TH", value: "th" },
        { text: "Kasir", value: "transaksi.akun.nama" },
        { text: "Validasi", value: "ok" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      search: "",
      tonase: [],
      tabelFilter: {
        cara: 0,
        tanggal: [new Date().toISOString().substr(0, 10)],
        bln: "",
        toke: 0,
        pabrik: 0,
        akun: 0,
      },
      deo: [],
      toke: [],
      akun: [],
      pabrik: [],
      terpilih: [],
      dialogTo: false,
      fieldExcell: {
        Pabrik: "do.pabrik.pabrik",
        Tanggal: "tanggaldo",
        Toke: "toke.nama",
        Supir: "supir",
        Plat: "plat",
        Tonase: {
          field: "tonase",
          callback: (value) => {
            return numeral(parseFloat(value) || 0).format("0,0.0");
          },
        },
        Harga: {
          field: "harga",
          callback: (value) => {
            return numeral(parseFloat(value) || 0).format("0,0.0");
          },
        },
        TH: {
          field: "th",
          callback: (value) => {
            return numeral(parseFloat(value) || 0).format("0,0.0");
          },
        },
        OPS: "ops",
        Kasir: "transaksi.akun.nama",
      },
      dataTgl: {
        jenis: 1,
        data: [moment().format("MM"), moment().format("YYYY")],
        tertulis: moment().format("MMMM-YYYY"),
      },
      menu: false,
      pemicuRekap: false,
    };
  },
  watch: {},
  computed: {
    yangDipilih() {
      return this.terpilih.map((e) => e.id);
    },
    filename() {
      return `Rekap Tonase -${moment().format("DD-MM-YY hh")}`;
    },
  },
  mounted() {
    this.inisialisasi();
    this.getToke();
    this.getDeo();
    this.getPabrik();
    this.getAkun();
  },
  methods: {
    inisialisasi() {
      this.loading = true;
      let uri = "";
      if (this.dataTgl.jenis == 1) {
        uri = `tonase/${this.dataTgl.data[0]}/${this.dataTgl.data[1]}`;
      } else {
        uri = `tonase/tanggal/${this.dataTgl.data[0]}/${this.dataTgl.data[1]}`;
      }
      console.log(uri);
      axios.get(uri).then((response) => {
        this.tonase = _.values(response.data);
        this.Validasi();
        this.tabel = this.tonase;
        console.log(this.tonase);
        this.loading = false;
      });
      this.terpilih = [];
    },
    updateBln(item) {
      this.dataTgl = item;
      this.menu = false;
      this.inisialisasi();
    },
    Validasi() {
      this.tonase.map(function (item) {
        item.ok = !!parseInt(item.ok);
        if (item.transaksi) {
          item.transaksi.akun.nama = item.transaksi.akun.nama + "/lama";
        } else {
          item.transaksi = {
            akun: { id: item.akuns.ID, nama: item.akuns.nama },
          };
        }
        item.supir = item["nama supir"];
        return item;
      });
    },
    filterBln() {
      this.inisialisasi();
    },
    editItem(item) {
      this.dialogTon = true;
      this.idTon = item.id;
    },
    update(ton) {
      console.log(ton);
      this.tonase = this.tabel.map(function (data) {
        if (data.id == ton.id) {
          data.do.pabrik.pabrik = ton.pabrik;
          data.tanggaldo = ton.tanggaldo;
          data.toke.nama = ton.txtoke;
          data.supir = ton.supir;
          data.plat = ton.plat;
          data.tonase = ton.tonase;
          return data;
        }
        return data;
      });
      this.dialogTon = false;
    },
    editPlat() {
      console.log("Edit plat mobil");
    },
    refresh(item) {
      this.tonase = item;
    },
    reset() {
      this.tonase = this.tabel;
    },
    bukaToke() {
      if (this.yangDipilih.length > 0) {
        this.dialogTo = true;
      } else {
        alert("Centang dulu baru klik tombol ini");
      }
    },
    SimpanToke(toke) {
      let uri = `/tonase/gantitoke/${toke.ID}`;
      axios
        .put(uri, this.yangDipilih)
        .then((response) => {
          if (response.data.status) {
            this.$noty.success(response.data.messages);
            this.yangDipilih.forEach((element) => {
              this.tabel = this.tabel.map(function (data) {
                if (data.id == element) {
                  data.toke.nama = toke.nama;
                  return data;
                }
                return data;
              });
            });
            console.log(response.data.data);
            this.dialogTo = false;
            this.terpilih = [];
          }
        })
        .catch((error) => {
          if (error.length >> 0) {
            this.$noty.error(error.response.data.errors[0]);
            console.log(error.response.data.errors);
          }
        });
    },
    save(plate) {
      let uri = `/tonase/plat/${plate.id}`;
      axios
        .put(uri, plate)
        .then((response) => {
          if (response.data.status) {
            this.$noty.success(response.data.messages);
          }
        })
        .catch((error) => {
          this.$noty.error(error.response.data.errors[0]);
          console.log(error.response.data.errors);
        });
      console.log(plate.id);
    },
    simpanValidasi() {
      if (this.yangDipilih.length > 0) {
        let uri = "/tonase/validasi/1";
        axios
          .put(uri, this.yangDipilih)
          .then((response) => {
            if (response.data.status) {
              this.$noty.success(response.data.messages);
              this.yangDipilih.forEach((element) => {
                this.tabel = this.tabel.map(function (data) {
                  if (data.id == element) {
                    data.ok = true;
                    return data;
                  }
                  return data;
                });
              });
              console.log(response.data.data);
              this.dialogTo = false;
              this.terpilih = [];
            }
          })
          .catch((error) => {
            if (error.length >> 0) {
              this.$noty.error(error.response.data.errors[0]);
              console.log(error.response.data.errors);
            }
          });
      } else {
        this.$noty.error("pilih dulu");
      }
    },
    bukaGantiDo() {
      if (this.yangDipilih.length > 0) {
        this.dialogDeo = true;
      } else {
        alert("Centang dulu baru klik tombol ini");
      }
    },
    tutupDialog() {
      this.dialogDeo = false;
    },
    reload(pabrik) {
      this.dialogDeo = false;

      let namaPabrik = this.pabrik.find(function (x) {
        return x.id == pabrik;
      }).pabrik;
      this.yangDipilih.forEach((element) => {
        this.tonase.find(function (x) {
          return x.id == element;
        }).do.pabrik.pabrik = namaPabrik;
      });
      this.terpilih = [];
    },

    cancel() {
      console.log("dicancel");
    },
    open() {
      console.log("open");
    },
    close() {
      console.log("close");
    },
    getToke() {
      axios.get("/tokeAktif").then((response) => {
        this.toke = response.data;
        this.toke.push({
          ID: 0,
          nama: "--Semua toke--",
        });
      });
    },
    getDeo() {
      axios.get("/deo").then((response) => {
        this.deo = response.data;
      });
    },
    getPabrik() {
      axios.get("/pabrik").then((response) => {
        this.pabrik = response.data;
        this.pabrik.push({
          id: 0,
          pabrik: "---Semua Pabrik----",
        });
      });
    },
    getAkun() {
      axios.get("/akun").then((response) => {
        this.akun = response.data;
        this.akun.push({
          id: 0,
          nama: "----Semua Akun----",
        });
      });
    },
  },
};
</script>
