<template>
  <div>
    <h2>{{ loading ? "loading.." : "Ini halaman test" }}</h2>
    <h3>{{ id }}</h3>
    <p>sum ton: {{ sumTon | angka }}</p>
    <p>sum Pinjaman: {{ sumPinjaman | rupiah }}</p>

    <autocomplete
      :items="toke"
      v-model="idTerpilih"
      @updateTeks="updateTeks"
      :teks="teksCari"
    ></autocomplete>
    <!-- <div>cari <autocomplete :items="coba" @isidata="ganti"></autocomplete></div> -->
    {{ idTerpilih }}
    <div>
      <h1>ini sedang di print</h1>
      <p class="pa-10">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis
        commodi atque, ipsum unde recusandae harum nobis magnam perferendis
        dolor voluptas cum maiores corporis aliquam similique, consequatur
        blanditiis tempora itaque ut!
      </p>
    </div>
    <div><excel></excel></div>

    <v-btn @click="sumNota">Cek</v-btn>
    <v-btn @click="print">Print</v-btn>
    <v-btn @click="bukaTabBaru">buka Tab Baru</v-btn>
    <v-btn @click="scroll">Go To</v-btn>
    <div id="yangdiprint">
      <v-card>
        <v-card-title>Print this</v-card-title>
        <div class="text-uppercase text-bold">id selected: {{ selected }}</div>
        <table id="siji">
          <thead>
            <tr>
              <label class="form-checkbox">
                <input type="checkbox" v-model="selectAll" @click="select"
              /></label>
              <th class="text-left">No</th>
              <th class="text-left">Tanggal</th>
              <th class="text-left">Supir</th>
              <th class="text-left">Plat</th>
              <th class="text-left">Tonase</th>
              <th class="text-left">Harga</th>
              <th class="text-left">Cair</th>
              <th class="text-left">OPS</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in tonase"
              :key="item.nou"
              :class="selected.indexOf(item.nou) > -1 ? 'cyan dark' : ''"
              @click="rowClicked(item)"
              :id="'goto' + item.id"
            >
              <label class="form-checkbox">
                <input type="checkbox" :value="item.id" v-model="selected" />
              </label>
              <td>{{ item.id }}</td>
              <td>{{ item.tanggaldo | formatDate }}</td>
              <td>{{ item.supir }}</td>
              <td>{{ item.plat }}</td>
              <td>{{ item.tonase }}</td>
              <td>
                {{ (parseInt(item.harga) + parseInt(item.th)) | rupiah }}
              </td>
              <td>
                {{
                  (item.tonase * (parseInt(item.harga) + parseInt(item.th)))
                    | rupiah
                }}
              </td>
              <td>{{ item.ops | rupiah }}</td>
            </tr>
          </tbody>
        </table>
      </v-card>
    </div>
  </div>
</template>
<style>
table {
  border-collapse: collapse;
}
td {
  padding: 2px 10px;
  margin: 0px;
  border-bottom: 0.5px solid grey;
  border-collapse: collapse;
  font-size: 0.9rem;
}
tr {
  border-collapse: collapse;
}
</style>
<script>
import autocomplete from "./autocomplete-r-coba.vue";
import excel from "./excel.vue";
import axios from "axios";
export default {
  components: { autocomplete, excel },
  computed: {
    sumTon() {
      return this.tonase.reduce((a, b) => a + parseInt(b.tonase), 0);
    },
    beberapaToke() {
      if (this.toke.length >> 5) {
        return this.toke.slice(0, 5);
      } else {
        return this.toke;
      }
    },
  },
  data() {
    return {
      idAkun: [],
      id: 6484,
      notas: [],
      tonase: [],
      pinjaman: [],
      loading: true,
      toke: [],
      tokePilih: 0,
      selected: [],
      selectAll: false,
      coba: ["nama", "nama saya", "nama kamu", "oke", "bias"],
      publicPath: process.env.BASE_URL,
      cek: true,
      teksCari: "",
      idTerpilih: 0,
    };
  },
  mounted() {
    this.getAkun();
    this.getNotas();
    this.getToke();
    console.log("<%= BASE_URL %>favicon.ico");
  },
  watch: {},
  methods: {
    scroll() {
      var rows = document.querySelectorAll("#siji tr");

      // line is zero-based
      // line is the row number that you want to see into view after scroll
      rows[10].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },
    print() {
      this.$htmlToPaper("yangdiprint", {
        styles: ["./css/print.css"],
      });
    },
    bukaTabBaru() {
      const { href } = this.$router.resolve({
        path: "/print/nota/6382",
      });
      window.open(href, "_blank");
    },
    getAkun() {
      let uri = "/akun";
      axios.get(uri).then((response) => {
        this.idAkun = response.data;
        this.loading = false;
      });
    },
    getToke() {
      let uri = "/toke";
      axios.get(uri).then((response) => {
        this.toke = response.data;
        this.loading = false;
      });
    },
    getNotas() {
      this.loading = true;
      let uri = "/transaksi/" + this.id + "/nota";
      axios.get(uri).then((response) => {
        this.notas = response.data;
        this.getIsiNotas();
      });
    },
    getIsiNotas() {
      let uri = "/transaksi/" + this.id + "/nota/rinci";
      axios.get(uri).then((response) => {
        this.tonase = response.data.tonase;
        this.pinjaman = response.data.pinjaman;
        this.getAkun();
      });
    },
    filterPinjaman(id) {
      return this.pinjaman.filter((x) => x.id_gabung == id);
    },
    sumPinjaman(arrPInjaman) {
      return arrPInjaman.reduce((a, b) => a + parseInt(b.hitung), 0);
    },
    sumPencairan(arrPencairan) {
      return arrPencairan.reduce(
        (a, b) => a + parseInt(b.tonase) * (parseInt(b.harga) + parseInt(b.th)),
        0
      );
    },
    filterTonase(id) {
      return this.tonase.filter((x) => x.id_gabung == id);
    },
    sumNota() {
      let tabel = [];
      this.notas.forEach((item) => {
        let tbPinjam = this.filterPinjaman(item.ID);
        let tbCair = this.filterTonase(item.ID);
        let pinjam = this.sumPinjaman(tbPinjam);
        let cair = this.sumPencairan(tbCair);
        tabel.push({
          id: item.ID,
          pinjaman: tbPinjam,
          pencairan: tbCair,
          sumPinjaman: pinjam,
          sumPencairan: cair,
          total: pinjam + cair,
        });
      });
      console.log(tabel);
    },
    goto() {
      this.$vuetify.goTo("#goto109575");
    },
    filterToke(item, queryTex) {
      //   var re = new RegExp(queryTex + ".*", "g");
      // var re = /toke .*/;
      // return re.test(item.nama);
      //   item.nama.toLocaleLowerCase().test(re);
      // item.nama.toLocaleLowerCase().indexOf(queryTex.toLocaleLowerCase()) > -1
      return (
        item.nama.substr(0, queryTex.length).toLocaleLowerCase() ==
        queryTex.toLocaleLowerCase()
      );
    },
    pilih() {
      this.cek = false;
      console.log(this.tokePilih);
      this.cek = true;
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.tonase) {
          this.selected.push(this.items[i].id);
        }
      }
    },
    updateTeks(item) {
      this.teksCari = item;
    },
  },
};
</script>
