<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    persistent
    width="550"
  >
    <v-card dense tile>
      <v-card-title color="pink" dense
        ><v-row>
          Modal<v-spacer></v-spacer>
          <v-col cols="12" sm="5" md="5">
            <v-autocomplete
              v-model="akunKeluar"
              auto-select-first
              autofocus
              :items="akuns"
              item-text="nama"
              item-value="id"
              label="Akun Keluar"
              ref="akunKeluar"
              :rules="[(v) => !!v || 'Item is required']"
              required
            ></v-autocomplete> </v-col></v-row></v-card-title
      ><v-divider></v-divider>
      <v-card-text>
        <v-form
          @submit.prevent="simpan()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col cols="12" sm="5" md="5">
              <v-autocomplete
                v-model="modal.akun"
                auto-select-first
                :items="akuns"
                item-text="nama"
                item-value="id"
                label="Akun"
                ref="toke"
                :rules="[(v) => !!v || 'Item is required']"
                required
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="5" sm="5">
              <v-text-field
                label="Nominal"
                required
                :rules="[
                  (v) => v <= 100000000000 || 'Terlalu besar',
                  (v) => !isNaN(parseFloat(v)) || 'harus di isi angka',
                ]"
                v-model="modal.nominal"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col sm="1" md="1"
              ><v-btn color="green" dark type="submit" small fab
                ><v-icon>mdi-content-save-move</v-icon></v-btn
              ></v-col
            ><v-col sm="1" md="1">
              <v-spacer></v-spacer>
              <v-btn color="green" dark fab small @click.prevent="barisBaru()">
                <v-icon>mdi-plus</v-icon></v-btn
              ></v-col
            >
          </v-row>
        </v-form>
        <v-card>
          <div>
            <v-simple-table dense height="260" id="tbmodal">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">No</th>
                    <th class="text-left">Akun</th>
                    <th class="text-left">Nominal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in modalModal"
                    :key="item.nou"
                    :class="
                      selectedRows.indexOf(item.nou) > -1 ? 'cyan dark' : ''
                    "
                    @click="rowClicked(item)"
                  >
                    <td>{{ item.nou }}</td>
                    <td>{{ item.txAkun }}</td>
                    <td>{{ item.nominal | rupiah }}</td>
                  </tr>
                  <tr
                    :class="
                      selectedRows == modalModal.length + 1 ? 'cyan dark' : ''
                    "
                  >
                    <td>*</td>
                    <td></td>
                    <td>{{ total | rupiah }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-btn color="red" dark @click="tutup()">Batal</v-btn
        ><v-btn
          color="blue"
          dark
          @click="kirim()"
          :loading="loadingBtn"
          :disabled="loadingBtn"
          >Simpan</v-btn
        ></v-card-actions
      >
    </v-card>
  </v-dialog>
</template>
<style>
#tbmodal div {
  overflow: scroll;
}
/* #badan td {
  font-size: 0.8rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  margin: 0px;
} */
</style>
<script>
import axios from "axios";
export default {
  props: ["dialogModalBaru", "idAkun", "tanggal"],
  computed: {
    dialog: {
      get() {
        return this.dialogModalBaru;
      },
      set() {
        this.$emit("tutup");
        this.modalModal = [];
      },
    },
    total() {
      return this.modalModal.reduce((simpan, item) => {
        return (simpan += item.nominal);
      }, 0);
    },
  },
  watch: {
    dialogModalBaru() {
      this.getAkun();
      this.akunKeluar = parseInt(this.idAkun);
      this.kosongkan();
      this.modal.nou = 1;
    },
  },
  data() {
    return {
      modal: {
        id: 0,
        akun: 0,
        txAkun: "",
        uraian: "",
        masuk: 0,
        keluar: 0,
        tanggal: "",
        id_masukan: 0,
        saldo: 0,
        ok: 0,
        tipe_transaksi: "normal",
        kredit: 0,
        saldo2: 0,
        nominal: 0,
        nou: 1,
      },
      akunKeluar: 0,
      modalModal: [],
      selectedRows: [],
      valid: true,
      modalAkhir: [],
      loadingBtn: false,
      akuns: [],
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
      this.modalModal = [];
      this.modalAkhir = [];
    },
    scrollToBottom() {
      let dip = document.querySelectorAll("#tbmodal div");
      let a = parseInt(dip[0].scrollHeight);
      dip[0].scrollTop = a;
    },
    scrollToItem() {
      let dip = document.querySelectorAll("#tbmodal div");
      let a =
        (parseInt(this.selectedRows - 2) * parseInt(dip[0].scrollHeight)) /
        parseInt(this.modalModal.length);
      dip[0].scrollTop = a;
    },
    simpan() {
      this.$refs.form.validate();
      if (this.valid) {
        this.kondisikanModal();
        let copyan = Object.assign({}, this.modal);
        const index = this.modalModal.findIndex((e) => e.nou === copyan.nou);
        if (index === -1) {
          this.modalModal.push(copyan);
          this.modal.nou += 1;
          this.selectedRows = [this.modal.nou];
          this.kosongkan();
          this.scrollToBottom();
        } else {
          this.modalModal[index] = copyan;
          this.modal.nou += 1;
          this.selectedRows = [this.modal.nou];
          if (this.modal.nou > this.modalModal.length) {
            this.kosongkan();
          } else {
            this.isiObModal(this.modal.nou);
          }
          this.scrollToItem();
        }
      }
    },
    isiObModal(nou) {
      let hasil = this.modalModal.filter(function (item) {
        return item.nou == nou;
      });
      if (hasil.length > 0) {
        this.modal = Object.assign({}, hasil[0]);
      } else {
        this.kosongkan();
      }
    },
    kondisikanModal() {
      this.modal.nominal = parseInt(this.modal.nominal);
      this.modal.masuk = parseInt(this.modal.nominal);
      this.modal.txAkun = this.strAkun(this.modal.akun);
    },
    kosongkan() {
      this.modal.tanggal = this.tanggal;
      this.modal.nominal = 0;
      this.modal.keluar = 0;
      this.modal.masuk = 0;
    },
    strAkun(id) {
      const hasil = this.akuns.findIndex((e) => e.id === id);
      return this.akuns[hasil].nama;
    },
    barisBaru() {
      this.kosongkan();
      this.modal.nou = this.modalModal.length + 1;
      this.selectedRows = [this.modal.nou];
    },
    rowClicked(item) {
      this.selectedRows = [item.nou];
      this.isiObModal(item.nou);
    },
    danaKeluar() {
      let dana = [];
      let obKeluar = {};
      let obMasuk = {};
      this.modalModal.forEach((item) => {
        obKeluar = Object.assign({}, item);
        obKeluar.keluar = obKeluar.nominal;
        obKeluar.masuk = 0;
        obKeluar.akun = this.akunKeluar;
        obKeluar.uraian = "modal ke- " + this.strAkun(item.akun);
        console.log("nama 1" + this.akunKeluar);
        console.log(this.akunKeluar);
        dana.push(obKeluar);
        obMasuk = Object.assign({}, item);
        obMasuk.uraian = "modal dari- " + this.strAkun(this.akunKeluar);
        console.log("nama2");
        dana.push(obMasuk);
      });
      this.modalAkhir = dana;
    },
    kirim() {
      this.loadingBtn = true;
      if (this.akunKeluar) {
        this.danaKeluar();
        console.log(this.modalAkhir);
        let uri = "/transaksi/modal/banyak";
        axios
          .post(uri, this.modalAkhir)
          .then((response) => {
            if (response.data) {
              console.log(response.data);
              this.$noty.success("Data berhasil disimpan");
              this.modalModal = [];
              this.modalAkhir = [];
              this.dialog = false;
              this.$emit("reload");
              this.loadingBtn = false;
            }
          })
          .catch((e) => {
            this.loadingBtn = false;
            this.$noty.error(e.message);
          });
      } else {
        this.$refs.akunKeluar.focus();
      }
    },
    getAkun() {
      this.akuns = [];
      let uri = "/akun";
      axios.get(uri).then((response) => {
        this.akuns = response.data;
      });
    },
  },
};
</script>
