<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="400"
    persistent
  >
    <v-card dense tile>
      <v-card-title>Pilih Tanggal</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col cols="12">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="tglComputed"
                    clearable
                    label="Tanggal"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="date = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="tgl"
                  @change="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-btn
              color="blue"
              dark
              type="submit"
              :loading="loadingHarga"
              :disabled="loadingHarga"
              >Simpan</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click="tutup()">Batal</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment";
import axios from "axios";
export default {
  props: ["dialogTgl", "deo", "pabrik", "terpilih"],
  computed: {
    tglComputed() {
      return this.tgl ? moment(this.tgl).format("DD/MM/YYYY") : "";
    },
    dialog: {
      get() {
        return this.dialogTgl;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    dialogTgl() {
      this.getHarga();
      this.kondisikan();
      this.tbUpdate = [];
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      tgl: "",
      loading: false,
      harga: [],
      loadingHarga: true,
      tbUpdate: [],
      menu1: false,
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kirim() {
      this.simpan();
      this.loadingHarga = true;
      let uri = "/tonase/gantideo/1";
      console.log(this.tbUpdate);
      axios
        .put(uri, this.tbUpdate)
        .then((response) => {
          if (response.data) {
            this.$noty.success("data Berhasil di Update");
            this.loadingHarga = false;
            this.$emit("update");
          }
        })
        .catch((error) => {
          this.$noty.error(error.response.data.errors[0]);
        });
      //   https://packagist.org/packages/mavinoo/laravel-batch <cari di sini, bisa untuk update bareng></cari>
    },
    simpan() {
      this.terpilih.forEach((item) => {
        this.tbUpdate.push({
          id: item.id,
          do: item.do.id,
          harga: this.cariHarga(item.do.pabrik.id).harga,
          id_harga: this.cariHarga(item.do.pabrik.id).id,
          tanggaldo: this.tgl,
        });
      });
    },
    kondisikan() {
      this.tgl = this.terpilih[0] ? this.terpilih[0].tanggaldo : "";
      this.tbUpdate = [];
    },
    cariHarga(pabrik) {
      if (this.loadingHarga == false) {
        let tanggal = this.tgl;
        let hasil = this.harga.filter(function (item) {
          return (
            moment(tanggal).isSameOrAfter(item.tanggal) && pabrik == item.pabrik
          );
        });
        if (hasil.length > 0) {
          return hasil[hasil.length - 1];
        } else {
          return 0;
        }
      }
    },
    getHarga() {
      if (this.terpilih.length > 0) {
        let tgl = this.terpilih.sort((a, b) => {
          return new Date(b.tanggaldo) - new Date(a.tanggaldo);
        })[0].tanggaldo;
        let uri = "/harga/60hari/" + moment(tgl).format("YYYY-MM-DD");
        axios.get(uri).then((response) => {
          if (response.data) {
            this.harga = response.data;
            this.loadingHarga = false;
          } else {
            console.log("kesalahan uri:" + uri);
          }
        });
      }
    },
  },
};
</script>
