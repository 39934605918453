<template>
  <div>
    <v-row dense
      ><v-col cols="1">
        <v-btn dark small color="blue" @click="download"
          ><v-icon>mdi-table-large</v-icon></v-btn
        >
      </v-col>
      <v-col cols="12" md="8"
        ><v-btn color="orange" small dark @click.prevent="print" class="ml-2">
          <v-icon dark> mdi-printer </v-icon> </v-btn
        ><v-btn color="blue" small dark @click.prevent="bukatoke" class="ml-2">
          Filter<v-icon right dark> mdi-filter </v-icon>
        </v-btn>
        <v-btn
          color="success"
          small
          @click.prevent="dialogF = true"
          class="ml-2"
        >
          Pilih Relasi
        </v-btn>
        <v-btn color="red" small @click.prevent="tonase = tabel" class="ml-2">
          reset
        </v-btn> </v-col
      ><v-spacer></v-spacer
      ><v-col cols="12" md="2"
        ><v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          dense
        ></v-text-field
      ></v-col>
      <v-col cols="12" md="1">
        <v-checkbox
          class="ml-3"
          dense
          v-model="cek"
          color="orange"
          label="Relasi"
        >
        </v-checkbox></v-col
      ><v-col>
        <v-card dense>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="tonase"
              :search="search"
              :items-per-page="10"
              dense
              show-select
              v-model="terpilih"
              item-key="id"
              mobile-breakpoint="300"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-arrow-left-drop-circle-outline',
                nextIcon: 'mdi-arrow-right-drop-circle-outline',
              }"
              :loading="loading"
            >
              <template v-slot:item.tanggaldo="{ item }">
                <span>{{ item.tanggaldo | formatDate }}</span>
              </template>
              <template v-slot:item.pph="{ item }">
                <span>{{
                  ((item.tonase * item.do.pph * (item.harga + item.do.fee)) /
                    100)
                    | angka
                }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  class="mx-2"
                  icon
                  x-small
                  color="green"
                  @click.stop="editItem(item)"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <poptoke
      :pemicu="popupToke"
      :tonase="tonase"
      @simpan="refresh"
      @tutup="popupToke = false"
    >
    </poptoke>
    <poprelasi
      :pemicu="dialogF"
      @tutup="dialogF = false"
      @simpan="refreshTabel"
      :ditandai="terpilih"
    >
    </poprelasi>
    <printakun
      :pemicu="pemicuPrint"
      @tutup="pemicuPrint = false"
      :data="dataPrint"
      :bln="bln"
      :thn="thn"
    ></printakun>
  </div>
</template>
<script>
import poptoke from "./popuptoke.vue";
import poprelasi from "./popupRelasi.vue";
import printakun from "./printAkuns.vue";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
export default {
  components: { poptoke, poprelasi, printakun },
  props: ["thn", "bln"],
  data() {
    return {
      tabel: [],
      popupToke: false,
      loading: true,
      max25chars: (v) => v.length <= 25 || "Input too long!",
      headers: [
        {
          text: "Pabrik",
          align: "start",
          filterable: true,
          value: "do.pabrik.pabrik",
        },
        { text: "Tanggal", value: "tanggaldo" },
        { text: "Toke", value: "toke.nama" },
        { text: "Supir", value: "nama supir" },
        { text: "Plat", value: "plat" },
        { text: "Tonase", value: "tonase" },
        { text: "th", value: "th" },
        { text: "hasil PPH", value: "pph" },
        { text: "Fee Relasi", value: "relasi.PEE" },
        { text: "Relasi", value: "relasi.nama" },
        { text: "Kasir", value: "transaksi.akun.nama" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      fieldExcell: {
        Id: "id",
        Tanggal: "tanggaldo",
        Toke: "toke.nama",
        Nama: "nama supir",
        Plat: "plat",
        tonase: "tonase",
        th: "th",
        fee: "do.fee",
        pabrik: "do.pabrik.pabrik",
        hasilpph: "hasilpph",
        feeRelasi: "relasi.PEE",
        Relasi: "relasi.nama",
      },
      search: "",
      tonase: [],
      deo: [],
      toke: [],
      akun: [],
      pabrik: [],
      terpilih: [],
      dialogTo: false,
      dialogF: false,
      cek: false,
      dataPrint: [],
      pemicuPrint: false,
    };
  },
  watch: {
    cek() {
      console.log(this.cek);
      if (this.cek) {
        this.tonase = this.tonase.filter((x) => {
          return parseInt(x.relasi) == 0 || x.relasi === null;
        });
      } else {
        this.tonase = this.tabel;
      }
    },
  },
  computed: {
    yangDipilih() {
      return 0;
    },
    filename() {
      return `Ton Tutup -${moment().format("DD-MM-YY hh")}`;
    },
  },
  mounted() {
    this.inisialisasi();
    this.getDeo();
    this.getPabrik();
    this.getAkun();
  },
  methods: {
    inisialisasi() {
      this.loading = true;
      axios
        .get("/tonase/tutupbuku/" + this.thn + "/" + this.bln)
        .then((response) => {
          this.tonase = this.tambahKolom(_.values(response.data));
          this.tabel = this.tonase;
          console.log(this.tonase);
          this.loading = false;
        });
      this.terpilih = [];
    },
    tambahKolom(arr) {
      let arrBa = [];
      arr.forEach((x) => {
        x.hasilpph = parseInt(
          (parseInt(x.tonase) *
            (parseInt(x.harga) + parseInt(x.do.fee)) *
            parseFloat(x.do.pph)) /
            100
        );
        if (x.transaksi) {
          x.transaksi.akun.nama = x.transaksi.akun.nama + "/lama";
        } else {
          x.transaksi = { akun: { id: x.akuns.ID, nama: x.akuns.nama } };
        }
        x.th = numeral(parseFloat(x.th) || 0).format("0,0.0");
        arrBa.push(x);
      });
      return arrBa;
    },
    editItem(item) {
      this.dialogTon = true;
      this.idTon = item.id;
    },
    refresh(item) {
      this.tonase = item;
    },
    refreshTabel() {
      this.inisialisasi();
      console.log("di update datannya");
    },
    editPlat() {
      console.log("Edit plat mobil");
    },
    bukatoke() {
      this.popupToke = true;
    },
    open() {
      console.log("open");
    },
    close() {
      console.log("close");
    },
    getDeo() {
      axios.get("/deo").then((response) => {
        this.deo = response.data;
      });
    },
    getPabrik() {
      axios.get("/pabrik").then((response) => {
        this.pabrik = response.data;
        this.pabrik.push({
          id: 0,
          pabrik: "---Semua Pabrik----",
        });
      });
    },
    getAkun() {
      axios.get("/akun").then((response) => {
        this.akun = response.data;
        this.akun.push({
          id: 0,
          nama: "----Semua Akun----",
        });
      });
    },
    print() {
      this.dataPrint = this.tonase.reduce((chace, item) => {
        const properti = item.relasi ? item.relasi.akun : 0;
        if (chace.some((el) => el.idAkun === properti)) {
          var terpilih = chace.find(function (x) {
            return x.idAkun === properti;
          });
          terpilih.data.push(item);
          return [...chace];
        }
        chace.push({
          idAkun: properti,
          Akun: item.relasi ? item.relasi.akuns.nama : "kosong",
          data: [item],
        });
        return [...chace];
      }, []);
      console.log(this.dataPrint);
      this.pemicuPrint = true;
    },
    download() {
      window.open(
        `https://smjakd.online/core/api/download/tonase/tutupbuku/${this.bln}/${this.thn}`
      );
    },
  },
};
</script>
