<template>
  <div>
    <v-row
      ><v-btn color="pink" dark @click="print" class="ml-3"
        ><v-icon>mdi-printer</v-icon></v-btn
      >
      <download-excel
        class="mx-2"
        :data="tabel"
        :fields="fieldExcell"
        worksheet="My Worksheet"
        :name="filename"
      >
        <v-btn dark color="blue"><v-icon>mdi-table-large</v-icon></v-btn>
      </download-excel></v-row
    >
    <v-row class="lebar" id="opsBdSiji">
      <v-col>
        <v-card>
          <v-card-title>
            <h4>Rincian Pengeluaran OPS periode {{ periode() }}</h4>
          </v-card-title>
          <div>
            <table class="tabel">
              <tbody v-for="items in bd" :key="items.id">
                <tr class="regional">
                  <td colspan="5">
                    <h4 class="text-uppercase">
                      {{ items.id == 45 ? "Bang doni" : items.toke }}
                    </h4>
                  </td>
                </tr>
                <tr class="judul">
                  <th>No</th>
                  <th>Tanggal</th>
                  <th>Uraian</th>
                  <th>Pinjam</th>
                  <th>Dikembalikan</th>
                </tr>
                <tr
                  v-for="(item, index) in items.pinjam"
                  :key="item.id"
                  class="isiTabel"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.tanggal | formatDate }}</td>
                  <td>{{ item.uraian }}</td>
                  <td>{{ item.pinjam | rupiah }}</td>
                  <td>{{ item.angsur | rupiah }}</td>
                </tr>
                <tr class="saldo">
                  <td colspan="3"></td>
                  <td>
                    {{ items.totalPinjam | rupiah }}
                  </td>
                  <td>
                    {{ items.totalAngsur | rupiah }}
                  </td>
                </tr>
                <tr class="saldo">
                  <td colspan="4">Total</td>
                  <td>
                    {{ (items.totalPinjam - items.totalAngsur) | rupiah }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-for="items in knth" :key="items.id">
            <br />
            <h4 class="text-uppercase">
              {{
                items.id == 151
                  ? "Pengeluaran Kantor "
                  : "Fee kasir dan Pijak Gas"
              }}
            </h4>
            <table class="tabel">
              <thead>
                <tr class="judul">
                  <th>No</th>
                  <th>Tanggal</th>
                  <th>Uraian</th>
                  <th>Pinjam</th>
                  <th>Dikembalikan</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in items.pinjam"
                  :key="item.id"
                  class="isiTabel"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.tanggal | formatDate }}</td>
                  <td>{{ item.uraian }}</td>
                  <td>{{ item.pinjam | rupiah }}</td>
                  <td>{{ item.angsur | rupiah }}</td>
                </tr>
                <tr class="saldo">
                  <td colspan="3">Total</td>
                  <td>
                    {{ items.totalPinjam | rupiah }}
                  </td>
                  <td>
                    {{ items.totalAngsur | rupiah }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <br />
          <table>
            <tbody>
              <tr v-for="(items, index) in bd" :key="items.id" class="saldo2">
                <td :class="index + 1 == bd.length ? 'garisbawah' : ''">
                  {{ items.id == 45 ? "Bang doni" : items.toke }}
                </td>
                <td :class="index + 1 == bd.length ? 'garisbawah' : ''">
                  {{ (items.totalPinjam - items.totalAngsur) | rupiah }}
                </td>
              </tr>
              <tr class="saldo2">
                <td></td>
                <td>{{ totalBD | rupiah }}</td>
              </tr>
              <tr v-for="(items, index) in knth" :key="items.id" class="saldo2">
                <td :class="index + 1 == knth.length ? 'garisbawah' : ''">
                  {{
                    items.id == 151
                      ? "Pengeluaran Kantor "
                      : "Fee kasir dan Pijak Gas"
                  }}
                </td>
                <td :class="index + 1 == knth.length ? 'garisbawah' : ''">
                  {{ (items.totalPinjam - items.totalAngsur) | rupiah }}
                </td>
              </tr>
              <tr class="saldo2">
                <td>Total</td>
                <td>{{ (totalBD + totalKN) | rupiah }}</td>
              </tr>
            </tbody>
          </table>
          <br />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<style type="text/css" media="print">
@import "@/assets/aplikasi/riz/opsbd.css";
</style>

<script>
import axios from "axios";
import moment from "moment";
export default {
  components: {},
  props: ["bln", "thn"],
  data() {
    return {
      tabel: [],
      bd: [],
      knth: [],
      fieldExcell: {
        Toke: "toke.nama",
        Tanggal: "tanggal",
        Pinjam: "pinjam",
        Angsur: "angsur",
        Uraian: "uraian",
      },
    };
  },
  computed: {
    totalBD() {
      return this.bd.reduce(function (chace, item) {
        chace = chace + item.totalPinjam;
        chace = chace - item.totalAngsur;
        return chace;
      }, 0);
    },
    filename() {
      return `ops bd -${moment().format("DD-MM-YY hh")}`;
    },
    totalKN() {
      return this.knth.reduce(function (chace, item) {
        return chace + item.totalPinjam - item.totalAngsur;
      }, 0);
    },
    totalPinjam() {
      return 0;
    },
  },
  mounted() {
    this.loadData();
  },
  watch: {},
  methods: {
    loadData() {
      const url = "/toke/bd/" + this.thn + "/" + this.bln;
      axios.get(url).then((response) => {
        this.tabel = response.data;
        console.log(this.tabel);
        this.addSaldo();
        this.filterTabel();
        this.pisahkan();
      });
    },
    addSaldo() {
      //   for (let a = 0; a < this.tabel.length; a++) {
      //     this.tabel[a].saldo =
      //       (parseInt(this.tabel[a].pinjaman_sum_hitung) || 0) -
      //       (parseInt(this.tabel[a].pinjaman_sum_angsur) || 0) +
      //       (parseInt(this.tabel[a].pinjaman_sum_pinjam) || 0);
      //   }
    },
    filterTabel() {
      //   let hasil = this.tabel.filter(function (item) {
      //     return item.saldo << -10000 && item.saldo >> 10000;
      //   });
      //   this.tabel = hasil;
    },
    pisahkan() {
      this.bd = this.grup(
        this.tabel.filter(function (x) {
          return x.toke.golongan == 4;
        })
      );
      this.knth = this.grup(
        this.tabel.filter(function (x) {
          return x.toke.golongan == 3 || x.toke.golongan == 5;
        })
      );
    },
    grup(array) {
      if (array.length >> 0) {
        array = array.reduce(function (chace, item) {
          const properti = item.toke.ID;
          if (chace.some((el) => el.id === properti)) {
            var a = chace.find(function (x) {
              return x.id === properti;
            });
            a.pinjam.push({
              tanggal: item.tanggal,
              uraian: item.uraian,
              pinjam: item.pinjam,
              angsur: item.angsur,
            });
            a.totalPinjam += parseInt(item.pinjam);
            a.totalAngsur += parseInt(item.angsur);
            return [...chace];
          }
          chace.push({
            id: item.toke.ID,
            toke: item.toke.nama,
            totalPinjam: parseInt(item.pinjam),
            totalAngsur: parseInt(item.angsur),
            pinjam: [
              {
                tanggal: item.tanggal,
                uraian: item.uraian,
                pinjam: item.pinjam,
                angsur: item.angsur,
              },
            ],
          });
          return [...chace];
        }, []);
        return array;
      }
      console.log(this.knth);
    },
    // sumPer(item, prop) {
    //   //   return item.reduce(function (a, b) {
    //   //     return a + (parseInt(b[prop]) || 0);
    //   //   }, 0);
    // },
    periode() {
      return moment(this.thn + "/" + this.bln, "YYYY/M").format("MMMM YYYY");
    },
    goPrint() {
      window.print();
    },
    print() {
      this.$htmlToPaper("opsBdSiji", {
        styles: ["/aplikasi/riz/app.css", "/aplikasi/riz/opsbd.css"],
      });
    },
  },
};
</script>
