<template>
  <v-row class="lebar">
    <v-col>
      <v-card>
        <v-card-title>
          <h4>Data mobil Toke</h4>
        </v-card-title>
        <table class="tabel">
          <thead>
            <tr class="judul">
              <th>No</th>
              <th>Toke</th>
              <th>Saldo Awal</th>
              <th>Pinjam</th>
              <th>Angsur</th>
              <th>Saldo</th>
            </tr>
          </thead>
          <tbody v-for="item in toke" :key="item.regional">
            <tr>
              <td class="regional">{{ item.regional }}</td>
            </tr>
            <tr
              v-for="(item, index) in item.toke"
              :key="item.ID"
              class="isiTabel"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ item.nama }}</td>
              <td>{{ item.pinjaman_sum_hitung | rupiah }}</td>
              <td>{{ item.pinjaman_sum_pinjam | rupiah }}</td>
              <td>{{ item.pinjaman_sum_angsur | rupiah }}</td>
              <td>{{ item.saldo | rupiah }}</td>
            </tr>
            <tr class="saldo">
              <td colspan="2">Total</td>
              <td>
                {{ item.sumAwal | rupiah }}
              </td>
              <td>
                {{ item.sumPinjam | rupiah }}
              </td>
              <td>
                {{ item.sumAngsur | rupiah }}
              </td>
              <td>
                {{ item.sumSaldo | rupiah }}
              </td>
            </tr>
          </tbody>
        </table>
        <br />
      </v-card>
    </v-col>
  </v-row>
</template>
<style type="text/css" media="print">
@page {
  size: portrait;
}
.isiTabel td {
  padding: 2px 8px 2px 8px;
  text-align: left;
  font-size: 1.2em;
  border: 1px solid black;
  border-collapse: collapse;
}
.judul {
  padding: 2px 8px 2px 8px;
  text-align: justify;
  font-size: 1.2em;
  border: 1px solid black;
  border-collapse: collapse;
}
.tabel {
  border: 1px solid black;
  border-collapse: collapse;
}
.tabel th {
  padding: 2px 8px 2px 8px;
  text-align: center;
  font-size: 1.2em;
  border: 1px solid black;
  border-collapse: collapse;
}
.saldo td {
  padding: 2px 8px 2px 8px;
  text-align: center;
  font-size: 1.2em;
  border: 1px solid black;
  border-collapse: collapse;
  font-weight: bold;
}
.regional {
  padding: 2px 8px 2px 8px;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
}

.lebar {
  padding-left: 50px;
}
</style>

<script>
export default {
  components: {},
  data() {
    return {
      toke: [],
    };
  },
  computed: {},
  mounted() {
    this.loadData();
  },
  watch: {},
  methods: {
    loadData() {
      //   axios
      //     .get(
      //       "/api/toke/listsaldo/" + this.jenis + "/" + this.bln + "/" + this.thn
      //     )
      //     .then((response) => {
      //       this.toke = response.data;
      //       console.log(this.toke);
      //       this.addSaldo();
      //       this.filterTabel();
      //       this.grup();
      this.goPrint();
      //     });
    },
    goPrint() {
      window.print();
    },
  },
};
</script>
