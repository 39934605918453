<template>
  <v-dialog transition="dialog-bottom-transition" v-model="dialog" persistent>
    <v-card dense tile>
      <v-toolbar dense color="purple lighten-2" dark>
        <v-toolbar-title> Tambah Transaksi</v-toolbar-title>
        <v-spacer></v-spacer>
        <download-excel
          class="mx-2"
          :data="aggTerpilih"
          :fields="fieldExcell"
          worksheet="My Worksheet"
          :name="filename"
        >
          <v-btn icon class="mx-2"><v-icon>mdi-table-large</v-icon></v-btn>
        </download-excel>
        |
        <v-icon @click="tutup" class="ml-3">mdi-close</v-icon>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-menu
              v-model="pupup"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="ubahTanggal"
                  label="Tanggal"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="date = null"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dataTransaksi.tanggal"
                @change="pupup = false"
                @click:date="editTanggal"
              ></v-date-picker>
            </v-menu>
            <div>Pilihan Belum cair</div>
            <v-btn-toggle v-model="toggle_none" dense>
              <v-btn
                v-for="a in namaPabrik"
                :key="a.id"
                @click="filter(a.id)"
                x-small
              >
                {{ a.bank }}
              </v-btn>
            </v-btn-toggle>
            <v-data-table
              :headers="headers"
              :items="aggPilihan"
              :items-per-page="6"
              dense
              mobile-breakpoint="300"
              :loading="loading"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-arrow-left-drop-circle-outline',
                nextIcon: 'mdi-arrow-right-drop-circle-outline',
              }"
            >
              <template v-slot:item.tanggal="{ item }">
                <span>{{ item.tanggal | formatDate }}</span>
              </template>
              <template v-slot:item.cair="{ item }">
                <span>{{ item.cair | rupiah }}</span>
              </template>
              <template v-slot:item.tonase="{ item }">
                <span>{{ item.tonase | angka }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  x-small
                  @click="masukkan(item)"
                  :disabled="tombol"
                >
                  <v-icon> mdi-arrow-right-circle </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <p>Terpilih</p>
            <v-data-table
              :headers="headers"
              :items="aggTerpilih"
              :items-per-page="6"
              dense
              mobile-breakpoint="300"
              :loading="loading"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-arrow-left-drop-circle-outline',
                nextIcon: 'mdi-arrow-right-drop-circle-outline',
              }"
            >
              <template v-slot:item.tanggal="{ item }">
                <span>{{ item.tanggal | formatDate }}</span>
              </template>
              <template v-slot:item.cair="{ item }">
                <span>{{ item.cair | rupiah }}</span>
              </template>
              <template v-slot:item.tonase="{ item }">
                <span>{{ item.tonase | angka }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  x-small
                  @click="keluarkan(item)"
                  :disabled="tombol"
                >
                  <v-icon> mdi-arrow-left-circle </v-icon>
                </v-btn>
              </template>
            </v-data-table>
            <p>Total Cair: {{ totalMasuk | rupiah }}</p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment";
import axios from "axios";
import _ from "lodash";
export default {
  props: ["pemicu", "transaksi", "akun", "idcair", "tanggal"],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    ubahTanggal() {
      return moment(this.tanggal).format("DD/MM/YYYY");
    },
    totalMasuk() {
      return this.aggTerpilih.reduce(function (cum, item) {
        return (cum += parseInt(item.cair));
      }, 0);
    },
    filename() {
      return `rekap masuk -${moment().format("DD-MM-YY hh")}`;
    },
  },
  watch: {
    pemicu() {
      if (this.pemicu) {
        this.inisialisasi();
        this.kondisikan();
      }
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      tombol: false,
      dataTransaksi: {
        do: 0,
        daritanggal: "",
        sampaitanggal: "",
        tanggal: "",
        bank: 0,
        uraian: "",
        pembanding: 0,
        pabrik: 0,
      },
      dataIdCair: {},
      loading: false,
      aggPilihanSumber: [],
      pilihanSumber: [],
      pilihan: [],
      aggPilihan: [],
      aggTerpilih: [],
      terpilih: [],
      namaPabrik: [],
      dataMasuk: [],
      dataBuang: [],
      toggle_none: null,
      pupup: false,
      headers: [
        {
          text: "Tanggal",
          align: "start",
          filterable: true,
          value: "tanggal",
        },
        { text: "Pabrik", value: "bank" },
        { text: "Tonase", value: "tonase" },
        { text: "Cair", value: "cair" },
        { text: "Actions", value: "actions" },
      ],
      fieldExcell: {
        Pabrik: "bank",
        Tanggal: "tanggal",
        Tonase: "tonase",
        Cair: "cair",
      },
      tabel: [],
    };
  },
  methods: {
    tutup() {
      console.log("ditutup");
      this.aggPilihanSumber = [];
      this.pilihanSumber = [];
      this.pilihan = [];
      this.aggPilihan = [];
      this.aggTerpilih = [];
      this.terpilih = [];
      this.namaPabrik = [];
      this.dataMasuk = [];
      this.dataBuang = [];
      this.toggle_none = null;
      this.$emit("tutup");
    },
    masukkan(item) {
      this.tombol = true;
      this.dataMasuk = this.pilihanSumber.filter(function (x) {
        return x.idpabrik.id === item.idpabrik && x.tanggal == item.tanggal;
      });
      var penghapus = this.dataMasuk;
      this.terpilih = [...this.terpilih, ...this.dataMasuk];
      this.aggTerpilih = this.grupkan(this.terpilih);
      this.pilihanSumber = this.pilihanSumber.filter(function (x) {
        return !penghapus.find(function (y) {
          return y.id === x.id;
        });
      });
      this.pilihanSumber = this.pilihanSumber.filter((x) => {
        return x.idpabrik.id == item.idpabrik;
      });
      this.pilihan = this.pilihanSumber;
      this.aggPilihanSumber = this.grupkan(this.pilihanSumber);
      this.aggPilihan = this.aggPilihanSumber;
      this.namaPabrikS();
      console.log("idcair: " + this.idcair);
      this.filter(item.idpabrik);
      if (this.idcair == 0) {
        this.dataTransaksi.daritanggal = item.tanggal;
        this.dataTransaksi.sampaitanggal = item.tanggal;
        this.dataTransaksi.uraian = "";
        this.dataTransaksi.pabrik = item.idpabrik;
        this.idTransaksiBaru();
      } else {
        this.dataTransaksi.daritanggal = this.terpilih.reduce(function (
          prev,
          current
        ) {
          return prev.tanggal < current.tanggal ? prev : current;
        }).tanggal;
        this.dataTransaksi.pabrik = item.idpabrik;
        this.dataTransaksi.sampaitanggal = this.terpilih.reduce(function (
          prev,
          current
        ) {
          return prev.tanggal > current.tanggal ? prev : current;
        }).tanggal;
        this.editTransaksi();
        this.tonMasuk(this.idcair);
      }
    },
    keluarkan(item) {
      this.tombol = true;
      this.dataBuang = this.terpilih.filter(function (x) {
        return x.idpabrik.id === item.idpabrik && x.tanggal == item.tanggal;
      });
      var penghapus = this.dataBuang;
      this.pilihanSumber = [...this.pilihanSumber, ...this.dataBuang];
      this.pilihan = this.pilihanSumber;
      this.aggPilihanSumber = this.grupkan(this.pilihanSumber);
      this.aggPilihan = this.aggPilihanSumber;
      this.namaPabrikS();
      this.terpilih = this.terpilih.filter(function (x) {
        return !penghapus.find(function (y) {
          return y.id === x.id;
        });
      });
      this.aggTerpilih = this.grupkan(this.terpilih);
      if (this.terpilih.length >> 0) {
        this.dataTransaksi.daritanggal = this.terpilih.reduce(function (
          prev,
          current
        ) {
          return prev.tanggal < current.tanggal ? prev : current;
        }).tanggal;
        this.dataTransaksi.sampaitanggal = this.terpilih.reduce(function (
          prev,
          current
        ) {
          return prev.tanggal > current.tanggal ? prev : current;
        }).tanggal;
      }
      this.tonKeluar();
    },
    tonMasuk(id) {
      var data = this.jadiArray(this.dataMasuk);
      let uri = "/tonase/cairbank/" + id + "/masuk";
      axios.put(uri, data).then((response) => {
        if (response.data.status) {
          console.log("insert berhasil>>");
          this.$noty.success("Data berhasil disimpan");
          this.masukkanSaldo();
        }
      });
    },
    tonKeluar() {
      var data = this.jadiArray(this.dataBuang);
      let uri = "/tonase/cairbank/" + this.idcair + "/keluar";
      axios.put(uri, data).then((response) => {
        if (response.data.status) {
          console.log("updATE berhasil>>");
          this.$noty.success("Data berhasil disimpan");
          this.masukkanSaldo();
        }
      });
    },
    idTransaksiBaru() {
      console.log("ini Transaksi baru");
      console.log("datanya");
      console.log(this.dataTransaksi);
      let uri = "/tanggalbank";
      axios.post(uri, this.dataTransaksi).then((response) => {
        if (response.data.status) {
          console.log("insert id transaksi baru berhasil>>");
          console.log(response.data.data);
          // this.$emit("insert", response.data.data);
          this.$noty.success("Data berhasil disimpan");
          this.$emit("insert", response.data.data.id);
          this.tonMasuk(response.data.data.id);
          this.tombol = false;
        }
      });
    },
    editTransaksi() {
      let uri = "/tanggalbank/" + this.idcair;
      axios.put(uri, this.dataTransaksi).then((response) => {
        if (response.data.status) {
          this.masukkanSaldo();
          this.$noty.success("Data berhasil disimpan");
        }
      });
    },
    masukkanSaldo() {
      let uri = "/transaksi/edit/" + this.idcair + "/pabrik";
      var data = {
        akun: this.dataTransaksi.bank,
        masuk: this.totalMasuk,
        tanggal: this.dataTransaksi.tanggal,
      };
      console.log(data);
      console.log(uri);
      axios.put(uri, data).then((response) => {
        if (response.data.status) {
          console.log("update saldo berhasil>>");
          console.log(response.data.data);
          this.$noty.success("Data berhasil disimpan");
          this.$emit("update");
          this.tombol = false;
        }
      });
    },
    inisialisasi() {
      this.loading = true;
      let url = "/tonase/cairbank/" + this.akun.id;
      axios.get(url).then((response) => {
        this.aggPilihan = this.grupkan(_.values(response.data));
        this.loading = false;
        this.pilihanSumber = _.values(response.data);
        this.pilihan = _.values(response.data);
        this.aggPilihanSumber = this.aggPilihan;
        this.namaPabrikS();
        this.loadDicairkan();
      });
    },
    loadDicairkan() {
      if (this.idcair >> 0) {
        this.loading = true;
        let url = "/tonase/cairbankTerpilih/" + this.idcair;
        axios.get(url).then((response) => {
          this.tabel = response.data;
          this.aggTerpilih = this.grupkan(_.values(response.data));
          this.terpilih = _.values(response.data);
          this.loading = false;
        });
      } else {
        this.tabel = [];
        this.terpilih = [];
        this.aggTerpilih = [];
      }
    },
    jadiArray(arr) {
      return arr.reduce(function (awal, step) {
        return awal.concat(step.id);
      }, []);
    },
    grupkan(array) {
      if (array.length >> 0) {
        var result = [];
        var hasil = [];
        array.reduce(function (res, value) {
          if (!res[value.tanggal]) {
            res[value.tanggal] = { tanggal: value.tanggal };
            result.push(res[value.tanggal]);
          }
          return res;
        }, {});
        result.forEach(function (val) {
          var a = array.filter(function (x) {
            return x.tanggal == val.tanggal;
          });
          var c = a.reduce(function (chace, item) {
            const properti = item.idpabrik.id;
            if (chace.some((el) => el.idpabrik === properti)) {
              var terpilih = chace.find(function (x) {
                return x.idpabrik === properti;
              });
              (terpilih.tonase += parseInt(item.tonase)),
                (terpilih.cair += parseInt(item.cair));
              return [...chace];
            }
            chace.push({
              tanggal: item.tanggal,
              tonase: parseInt(item.tonase),
              cair: parseInt(item.cair),
              bank: item.idpabrik.pabrik,
              idpabrik: item.idpabrik.id,
            });
            return [...chace];
          }, []);
          c.forEach(function (item) {
            hasil.push(item);
          });
        });
        hasil.sort(function (a, b) {
          return new Date(a.tanggal).getTime() - new Date(b.tanggal).getTime();
        });
        return hasil;
      }
      return array;
    },
    namaPabrikS() {
      var b = [];
      this.aggPilihan.reduce(function (chace, item) {
        const properti = item["idpabrik"];
        if (properti in chace) {
          return { ...chace };
        }
        b.push({ id: item.idpabrik, bank: item.bank });
        return { ...chace, [properti]: [item] };
      }, {});
      this.namaPabrik = b;
    },
    filter(a) {
      if (this.toggle_none == null) {
        this.aggPilihan = this.aggPilihanSumber.filter(function (x) {
          return x.idpabrik == a;
        });
      } else {
        this.aggPilihan = this.aggPilihanSumber;
      }
    },
    kondisikan() {
      if (this.idcair == 0) {
        console.log("Transaksi baru");
        this.dataTransaksi.daritanggal = "";
        this.dataTransaksi.sampaitanggal = "";
        this.dataTransaksi.uraian = "";
        this.dataTransaksi.pabrik = 0;
        this.dataTransaksi.bank = this.akun.id;
        this.dataTransaksi.tanggal = this.tanggal;
      } else {
        console.log("Transaksi sudah ada, edit");
        this.dataTransaksi.daritanggal = this.transaksi.daritanggal;
        this.dataTransaksi.sampaitanggal = this.transaksi.sampaitanggal;
        this.dataTransaksi.tanggal = this.transaksi.tanggal;
        this.dataTransaksi.uraian = this.transaksi.uraian
          ? this.transaksi.uraian
          : "";
        this.dataTransaksi.pabrik = this.transaksi.pabrik.id;
        this.dataTransaksi.bank = this.akun.id;
      }
      console.log(this.dataTransaksi);
    },
    editTanggal() {
      if (this.idcair == 0) {
        console.log("transaksi baru, gak perlu edit tanggal");
      } else {
        this.editTransaksi();
      }
    },
  },
};
</script>
