<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="300"
    persistent
  >
    <v-card dense tile>
      <v-card-title>Pilih Rentang Tanggal</v-card-title>
      <v-card-text>
        <v-form
          id="diprint"
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col cols="12">
              <v-date-picker
                v-model="tanggal"
                width="230"
                range
              ></v-date-picker>
            </v-col>
            <v-btn color="blue" dark @click="simpan">Simpan</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click="tutup()">Batal</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment";

export default {
  props: ["pemicu", "id"],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    ubahTanggal() {
      //   return this.dataTransaksi
      //     ? moment(this.dataTransaksi.tanggal).format("YYYY-MM-DD")
      //     : "";
      return 0;
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
      this.kondisikan();
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      pupup: false,
      loading: false,
      tglZero: moment().format("YYYY-MM-DD"),
      tanggal: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kirim() {},
    inisialisasi() {},
    simpan() {
      if (this.tanggal.length == 1) {
        this.tanggal.push(this.tanggal[0]);
      }
      this.tanggal.sort((a, b) => new Date(a) - new Date(b));
      this.$emit("simpan", this.tanggal);
      this.$emit("tutup");
      console.log(this.tanggal);
    },
    kondisikan() {
      this.tanggal = [
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ];
    },
  },
};
</script>
