<template>
  <v-row class="ma-2"
    ><v-col cols="12" md="3">
      <v-card class="elevation-1">
        <v-toolbar dense color="orange darken-2" dark>
          <v-toolbar-title>Pabrik</v-toolbar-title>
        </v-toolbar>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mx-2"
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="judulPabrik"
          :items="pabrik"
          :loading="loadingPabrik"
          hide-default-header
          @page-count="pageCount = $event"
          :page.sync="page"
          v-model="pabrikTerpilih"
          :search="search"
          hide-default-footer
          dense
        >
          <template v-slot:item="{ item }">
            <tr
              :class="
                pabrikTerpilih.indexOf(item.id) > -1
                  ? 'primary  white--text font-weight-bold'
                  : ''
              "
              @click="rowClicked(item)"
            >
              <td>{{ item.pabrik }}</td>
            </tr>
          </template>
        </v-data-table>
        <v-pagination v-model="page" :length="pageCount"></v-pagination>
      </v-card> </v-col
    ><v-col cols="12" md="9">
      <v-card color="grey lighten-4"
        ><v-toolbar dense color="pink" dark>
          <v-toolbar-title>List Relasi</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="baru">
            <v-icon>mdi-plus-thick</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <v-text-field
            v-model="cariRelasi"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="judulRelasi"
            :items="relasi"
            :items-per-page="8"
            :loading="loadingRelasi"
            :search="cariRelasi"
            v-model="relasiTerpilih"
            class="elevation-1"
            dense
          >
            <template v-slot:item.bank="{ item }">
              <span>{{ item.bank.nama }}</span>
            </template>

            <template v-slot:item.opsi="{ item }">
              <v-btn
                class="mx-2"
                icon
                x-small
                @click="edit(item)"
                :disabled="loadingHapus"
                ><v-icon> mdi-pencil </v-icon></v-btn
              >
              <v-btn
                class="mx-2"
                icon
                color="red"
                x-small
                @click="hapus(item)"
                :disabled="loadingHapus"
                ><v-icon> mdi-trash-can </v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <relasi
        :pemicu="pemicuRelasi"
        :id="id_relasi"
        @tutup="pemicuRelasi = false"
        @lanjutkan="tampilRelasi"
        :pabrik="id_pabrik"
      >
      </relasi>
    </v-col>
  </v-row>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import relasi from "./relasi.vue";
export default {
  components: { relasi },
  computed: {
    lastPage() {
      return Math.ceil(this.relasi.length / 8);
    },
  },
  data() {
    return {
      search: "",
      cariRelasi: "",
      pabrik: [],
      loadingPabrik: false,
      pabrikTerpilih: [],
      relasi: [],
      loadingRelasi: false,
      relasiTerpilih: [],
      loadingHapus: false,
      judulPabrik: [
        {
          text: "Nama Pabrik",
          align: "start",
          filterable: true,
          value: "pabrik",
        },
      ],
      judulRelasi: [
        {
          text: "Nama",
          value: "nama",
        },
        {
          text: "Fee",
          value: "PEE",
        },
        {
          text: "Akun",
          value: "akuns.nama",
        },
        {
          text: "Nama Print",
          value: "namaPrint",
        },
        {
          text: "Opsi",
          value: "opsi",
          align: "right",
        },
      ],
      pemicu: false,
      id_relasi: 0,
      pemicuRelasi: false,
      id_pabrik: 0,
      itemPabrik: {},
      page: 1,
      pageCount: 0,
      pemicuGrup: false,
    };
  },
  mounted() {
    this.inisialisasi();
  },
  watch: {},
  methods: {
    inisialisasi() {
      this.loading = true;
      let url = "/pabrik";
      axios.get(url).then((response) => {
        this.pabrik = _.values(response.data);
        this.loadingPabrik = false;
      });
    },
    bukaRelasi(id) {
      this.loadingRelasi = true;
      let url = `/pabrik/relasi/${id}`;
      axios.get(url).then((response) => {
        this.relasi = _.values(response.data);
        this.loadingRelasi = false;
        console.log(this.relasi);
      });
    },
    tampilRelasi() {
      this.bukaRelasi(this.pabrikTerpilih[0]);
    },
    rowClicked(item) {
      this.pabrikTerpilih = [item.id];
      this.id_pabrik = item.id;
      this.tampilRelasi();
    },
    refresh() {
      this.inisialisasi();
      this.tampilRelasi();
    },
    hapus(item) {
      this.loadingHapus = true;
      let uri = "/relasi/" + item.id;
      axios.delete(uri).then((response) => {
        this.$noty.success(response.data.pesan);
        this.loadingHapus = false;
        console.log(item.id);
        console.log(this.relasi);
        this.tampilRelasi();
      });
    },
    lanjutkan() {
      this.tampilRelasi();
    },
    tutup() {
      this.pemicu = false;
    },
    baru() {
      if (this.pabrikTerpilih[0] >> 0) {
        this.pemicuRelasi = true;
        this.id_relasi = 0;
      } else {
        this.$noty.error("Pilih pabrik dulu");
        console.log("pabrik haurs di pilih");
        console.log(this.pabrikTerpilih);
      }
    },
    edit(item) {
      this.id_relasi = item.id;
      this.pemicuRelasi = true;
    },
  },
};
</script>
