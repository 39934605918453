<template>
  <v-container>
    <v-row>
      <v-col cols="12"><h3>Selamat Datang</h3></v-col>
      <v-col>
        <v-row>
          <v-col cols="12">
            <v-form @submit.prevent="cariTon()" ref="form" v-model="valid">
              <v-row class="mx-4 ma-sm-6">
                <v-text-field
                  v-model="search"
                  label="Cari"
                  outlined
                  :rules="labelRules"
                  class="mx-1 mx-sm-6"
                ></v-text-field
                ><v-btn @click="cariTon" class="mx-1" color="blue" dark x-large
                  ><v-icon>mdi-magnify-expand</v-icon></v-btn
                >
              </v-row>
            </v-form></v-col
          >
          <v-col cols="12" v-if="visibleTabel">
            <v-card>
              <v-toolbar>
                <v-row
                  ><span class="ma-4">Hasil cari</span><v-spacer></v-spacer
                  ><v-btn class="mx-4" @click="visibleTabel = false" icon
                    ><v-icon>mdi-close</v-icon></v-btn
                  ></v-row
                >
                <template v-slot:extension>
                  <v-tabs align-with-title>
                    <v-tabs-slider color="yellow"></v-tabs-slider>
                    <v-tab
                      v-for="item in tabs"
                      :key="item"
                      @click="cekTab(item)"
                    >
                      {{ item }}
                    </v-tab>
                  </v-tabs>
                </template>
              </v-toolbar>
              <v-card-text>
                <div v-if="tab == 'Tonase'">
                  <v-data-table
                    v-if="visibleTabel"
                    :headers="kepalaTon"
                    :items="tonase"
                    :items-per-page="10"
                    dense
                    :loading="loading"
                    item-key="id"
                    mobile-breakpoint="300"
                    :footer-props="{
                      showFirstLastPage: false,
                      firstIcon: 'mdi-page-first',
                      lastIcon: 'mdi-page-last',
                      prevIcon: 'mdi-arrow-left-drop-circle-outline',
                      nextIcon: 'mdi-arrow-right-drop-circle-outline',
                    }"
                  >
                    <template v-slot:item.tanggaldo="{ item }">
                      <span>{{ item.tanggaldo | formatDate }}</span>
                    </template>
                    <template v-slot:item.Hitungcair="{ item }">
                      <span>{{
                        (item.tonase *
                          (parseInt(item.th) + parseInt(item.harga)))
                          | rupiah
                      }}</span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-btn
                        class="mx-2"
                        icon
                        x-small
                        color="green"
                        @click.stop="bukaTon(item)"
                      >
                        <v-icon> mdi-account-edit </v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </div>
                <div v-if="tab == 'Pinjaman'">
                  <v-data-table
                    v-if="visibleTabel"
                    :headers="kepalaPinjaman"
                    :items="pinjaman"
                    :items-per-page="10"
                    dense
                    :loading="loading"
                    item-key="id"
                    mobile-breakpoint="300"
                    :footer-props="{
                      showFirstLastPage: false,
                      firstIcon: 'mdi-page-first',
                      lastIcon: 'mdi-page-last',
                      prevIcon: 'mdi-arrow-left-drop-circle-outline',
                      nextIcon: 'mdi-arrow-right-drop-circle-outline',
                    }"
                  >
                    <template v-slot:item.pinjam="{ item }">
                      <span>{{ item.pinjam | rupiah }}</span>
                    </template>
                    <template v-slot:item.tanggal="{ item }">
                      <span>{{ item.tanggal | formatDate }}</span>
                    </template>
                    <template v-slot:item.angsur="{ item }">
                      <span>{{ item.angsur | rupiah }}</span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-btn
                        class="mx-2"
                        icon
                        x-small
                        color="green"
                        @click.stop="bukaPinjaman(item)"
                      >
                        <v-icon> mdi-account-edit </v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" v-else>
            <v-container>
              <v-row justify="center" class="rounded-3">
                <v-col cols="12">
                  <v-row align="center" justify="center"
                    ><v-col cols="6" sm="3">
                      <v-card elevation="24" max-width="250" class="mb-4">
                        <v-carousel
                          cycle
                          height="150"
                          hide-delimiter-background
                          show-arrows-on-hover
                        >
                          <v-carousel-item
                            v-for="(slides, i) in harga"
                            :key="i"
                          >
                            <v-row align="center" justify="center" class="my-2">
                              <table>
                                <tr v-for="(item, i) in slides" :key="i">
                                  <td>
                                    {{
                                      item.pabriks ? item.pabriks.pabrik : ""
                                    }}
                                  </td>
                                  <td>{{ item.harga }}</td>
                                </tr>
                              </table></v-row
                            >
                          </v-carousel-item>
                        </v-carousel>
                        <v-list two-line class="blue">
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title class="white--text"
                                >Harga</v-list-item-title
                              >
                              <v-list-item-subtitle class="white--text">{{
                                tanggal
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card> </v-col
                    ><v-col cols="6" sm="3">
                      <v-card elevation="24" max-width="250" class="mb-4">
                        <v-carousel
                          cycle
                          height="150"
                          hide-delimiter-background
                          show-arrows-on-hover
                        >
                          <v-carousel-item
                            v-for="(slides, i) in tokeSlide"
                            :key="i"
                          >
                            <v-row align="center" justify="center" class="my-2">
                              <table>
                                <tr v-for="(item, i) in slides" :key="i">
                                  <td>{{ item.nama }}</td>
                                </tr>
                              </table></v-row
                            >
                          </v-carousel-item>
                        </v-carousel>
                        <v-list two-line class="blue">
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title class="white--text"
                                >Toke</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card></v-col
                    ></v-row
                  >
                  <v-row justify="center">
                    <v-sheet
                      v-for="item in mainMenus"
                      class="ma-3"
                      :key="item.judul"
                      rounded
                    >
                      <v-row justify="center">
                        <v-col cols="7" align="center" class="pa-0"
                          ><v-btn large outlined :to="item.link"
                            ><v-icon large class="blue--text">{{
                              item.icon
                            }}</v-icon></v-btn
                          ></v-col
                        >
                        <v-col cols="7" class="blue--text pt-1" align="center"
                          ><div class="text-caption">
                            {{ item.judul }}
                          </div></v-col
                        ></v-row
                      >
                    </v-sheet>
                  </v-row></v-col
                >
              </v-row></v-container
            >
            <v-container>
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-row justify="center" align="center">
                    <v-col v-for="item in menus" class="ma-1" :key="item.judul">
                      <v-sheet>
                        <v-row justify="center">
                          <v-col cols="7" align="center" class="pa-0"
                            ><v-btn large color="blue" dark fab :to="item.link"
                              ><v-icon large>{{ item.icon }}</v-icon></v-btn
                            ></v-col
                          >
                          <v-col
                            cols="7"
                            class="blue--text pt-1 text-caption text-center"
                          >
                            {{ item.judul }}
                          </v-col></v-row
                        >
                      </v-sheet></v-col
                    >
                  </v-row></v-col
                >
              </v-row></v-container
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="pa-6 overflow-auto"> </v-col>

      <tonedit
        :id="idTon"
        :deo="deo"
        :toke="toke"
        :dialogTon="dialogTon"
        @tutup="tutup"
        @update="reload"
      ></tonedit>
      <pinjamanedit
        :dialogPinjaman="dialogPinjaman"
        :id="idToke"
        :toke="toke"
        @tutup="tutup"
        @update="reload"
      ></pinjamanedit>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import _ from "lodash";
import tonedit from "../Transaksi/input/tonaseEdit.vue";
import pinjamanedit from "../Transaksi/input/pinjamanEdit.vue";
import moment from "moment";
export default {
  components: {
    tonedit,
    pinjamanedit,
  },
  mounted() {
    console.log(this.user);
    this.getToke();
  },
  props: ["user"],
  computed: {},
  data() {
    return {
      search: "",
      items: [
        {
          judul: "Harga",
          icon: "mdi-currency-usd",
          link: "/harga",
          jenis: "mainMenus",
        },
        {
          judul: "Toke",
          icon: "mdi-account",
          link: "/toke",
          jenis: "mainMenus",
        },
        {
          judul: "Pinjaman",
          icon: "mdi-account",
          link: "/pinjaman",
          jenis: "mainMenus",
        },
        {
          judul: "Input Harga",
          icon: "mdi-currency-usd",
          link: "/harga",
          jenis: "menus",
        },
        {
          judul: "Supir/Toke",
          icon: "mdi-car",
          link: "/supirtoke",
          jenis: "menus",
        },
        {
          judul: "Pabrik",
          icon: "mdi-factory",
          link: "/pabrik",
          jenis: "menus",
        },
        { judul: "Bank", icon: "mdi-bank", link: "/bank", jenis: "menus" },
        {
          judul: "Kasir",
          icon: "mdi-bank",
          link: "/akunkasir/",
          jenis: "menus",
        },
        {
          judul: "Invoice",
          icon: "mdi-notebook-outline",
          link: "/invoice",
          jenis: "menus",
        },
        {
          judul: "Laporan",
          icon: "mdi-whatsapp",
          link: "/laporan",
          jenis: "menus",
        },
        {
          judul: "Relasi",
          icon: "mdi-account",
          link: "/relasi",
          jenis: "menus",
        },
        { judul: "OPS", icon: "mdi-gas-station", link: "/ops", jenis: "menus" },
        { judul: "User", icon: "mdi-account", link: "/user", jenis: "menus" },
        {
          judul: "Rekening",
          icon: "mdi-account",
          link: "/rekening",
          jenis: "menus",
        },
        {
          judul: "Transaksi Pabrik",
          icon: "mdi-factory",
          link: "/transaksipabrik",
          jenis: "menus",
        },
        {
          judul: "Perpajakan",
          icon: "mdi-percent-circle-outline",
          link: "/perpajakan",
          jenis: "menus",
        },
      ],
      level: [
        {
          level: "kasir",
          menu: ["Harga", "Toke", "Pinjaman"],
        },
        {
          level: "super_admin",
          menu: [
            "Harga",
            "Toke",
            "Pinjaman",
            "Input Harga",
            "Supir/Toke",
            "Pabrik",
            "Bank",
            "Kasir",
            "Invoice",
            "Laporan",
            "Relasi",
            "OPS",
            "User",
            "Rekening",
            "Transaksi Pabrik",
            "Perpajakan",
          ],
        },
        {
          level: "ramp",
          menu: ["Harga"],
        },
        {
          level: "kasir_limit",
          menu: ["Harga", "Invoice", "Bank"],
        },
      ],
      valid: false,
      tabs: ["Tonase", "Pinjaman"],
      tab: "Tonase",
      tonase: [],
      pinjaman: [],
      visibleTabel: false,
      labelRules: [(v) => !!v || "Harus di isi ya"],
      kepalaPinjaman: [
        {
          text: "Tanggal",
          align: "start",
          filterable: true,
          value: "tanggal",
        },
        { text: "Toke", value: "toke.nama" },
        { text: "Uraian", value: "uraian" },
        { text: "Pinjam", value: "pinjam" },
        { text: "Bayar", value: "angsur" },
        { text: "Kategori", value: "jenis" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      kepalaTon: [
        {
          text: "Pabrik",
          align: "start",
          filterable: true,
          value: "do.pabrik.pabrik",
        },
        { text: "Tanggal", value: "tanggaldo" },
        { text: "Toke", value: "toke.nama" },
        { text: "Supir", value: "nama supir" },
        { text: "Plat", value: "plat" },
        { text: "Tonase", value: "tonase" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
      loading: false,
      dialogTon: false,
      idTon: 0,
      idToke: 0,
      deo: [],
      toke: [],
      dialogPinjaman: false,
      menus: [],
      mainMenus: [],
      harga: [],
      tanggal: moment().format("DD/MM/YYYY"),
      tokeSlide: [],
    };
  },
  methods: {
    cariTon() {
      this.$refs.form.validate();
      if (this.valid) {
        this.visibleTabel = true;
        this.loading = true;
        let uri = "/tonase/cari/" + this.search;
        axios.get(uri).then((response) => {
          this.tonase = _.values(response.data.tonase);
          this.pinjaman = _.values(response.data.pinjaman);
          this.loading = false;
          console.log(this.tonase);
        });
        console.log(uri);
      }
    },
    getUser() {
      let level = this.level.find((x) => this.user.user_level == x.level);
      console.log(level);
      let items = this.items.filter((x) => {
        return level.menu.indexOf(x.judul) > -1;
      });
      this.mainMenus = items.filter((x) => x.jenis == "mainMenus");
      this.menus = items.filter((x) => x.jenis == "menus");
    },
    getToke() {
      let uri = "/tokeAktif";
      axios.get(uri).then((response) => {
        this.toke = response.data;
        let hargabagi = [];
        let index = 0;
        let toke = this.toke.filter(
          (x) => x.aktif == "aktif" && x.golongan == 1
        );
        toke.reduce((prev, item) => {
          index++;
          if (index < 6) {
            prev.push(item);
          } else {
            hargabagi.push(prev);
            prev = [];
            index = 0;
          }
          return prev;
        }, []);
        this.tokeSlide = hargabagi;
        console.log(this.tokeSlide);
        this.getDeo();
        this.getUser();
        this.tampilHarga();
      });
    },
    getDeo() {
      let uri = "/deo";
      axios.get(uri).then((response) => {
        this.deo = response.data;
      });
    },
    tutup() {
      this.dialogTon = false;
      this.dialogPinjaman = false;
    },
    reload() {
      this.dialogTon = false;
      this.dialogPinjaman = false;
    },

    bukaTon(item) {
      this.idTon = item.id;
      this.dialogTon = true;
    },
    bukaPinjaman(item) {
      this.idToke = item.ID;
      this.dialogPinjaman = true;
    },
    cekTab(item) {
      this.tab = item;
    },
    tampilHarga() {
      let url = "/harga/" + moment().format("yyyy-MM-DD");
      axios.get(url).then((response) => {
        this.harga = response.data.sort(
          (a, b) => new Date(b.tanggal) - new Date(a.tanggal)
        );
        let hargabagi = [];
        let index = 0;
        this.harga.reduce((prev, item) => {
          index++;
          if (index < 6) {
            prev.push(item);
          } else {
            hargabagi.push(prev);
            prev = [];
            index = 0;
          }
          return prev;
        }, []);
        this.harga = hargabagi;
        console.log(url);
        console.log(this.harga);
      });
    },
  },
};
</script>
