<template>
  <v-card>
    <v-toolbar dense color="blue darken-4" dark elevation="-2">
      <v-btn icon large @click="kembali">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title v-if="transaksi.akun">{{
        transaksi.akun.nama
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        fab
        large
        dark
        color="red"
        @click="transaksiDialog = true"
        :disabled="loading"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <template v-slot:extension>
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="blue"></v-tabs-slider>
          <v-tab v-for="item in items" :key="item" @click="idTab = item">
            {{ item }}
          </v-tab>
        </v-tabs>
      </template>
      <transaksibaru
        :transaksiDialog="transaksiDialog"
        @tutup="tutupDialog"
        @lanjutkan="lanjutan"
        :kasir="kasir"
        :tanggal="idTransaksi[0]"
      ></transaksibaru>
      <inputsaldo
        :dialogSaldo="dialogSaldo"
        @tutup="tutupDialog"
        @lanjutkan="updateSaldo"
        :id="id"
        :saldo="transaksi['saldo fakta']"
      ></inputsaldo>
    </v-toolbar>
    <v-row class="mt-2"
      ><v-col cols="12" md="3" v-if="!$vuetify.breakpoint.mdAndDown">
        <div class="mx-auto px-auto">
          <v-date-picker
            elevation="15"
            rounded
            v-model="tglTerpilih"
            :events="listTanggal"
            event-color="blue darken-3"
          ></v-date-picker></div></v-col
      ><v-col cols="12" :md="!$vuetify.breakpoint.mdAndDown ? 9 : ''">
        <v-card color="grey lighten-3">
          <v-row class="ma-1" dense>
            <v-col cols="12"
              ><span class="mx-4 text-h5"
                >{{ transaksi.tanggal | hari }} |
                {{ transaksi.tanggal | formatDate }}</span
              >
              <v-btn icon @click="pemicuDate = true" lg class="mb-2">
                <v-icon>mdi-calendar</v-icon></v-btn
              ></v-col
            >
            <v-col cols="12" md="9">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <span class="ma-3 text-left">
                        Saldo : {{ saldo.saldoIni | rupiah }}</span
                      >
                    </td>
                    <td>
                      <span class="ma-3 text-left">
                        <a class="pa-2 text-bold" @click="dialogSaldo = true"
                          >Saldo Fakta :
                          {{ transaksi["saldo fakta"] | rupiah }}</a
                        ></span
                      >
                    </td>
                    <td>
                      <span class="ma-3 text-left"
                        >Selisih: {{ selisih | angka }}</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn
                dark
                color="blue"
                outlined
                :loading="loadingRefresh"
                :disabled="loadingRefresh"
                @click="refreshTotal"
                >Simpan</v-btn
              ><v-btn @click="printIn" :disabled="loading" class="mx-2" small
                ><v-icon>mdi-printer</v-icon></v-btn
              ><v-btn @click="pemicuLog = true" :disabled="loading" small
                ><v-icon>mdi-printer-search</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card>
        <v-col cols="12" class="px-0 mb-3">
          <v-dialog
            transition="dialog-bottom-transition"
            v-model="pemicuDate"
            width="500"
            ><v-date-picker
              elevation="15"
              rounded
              v-model="tglTerpilih"
              :events="listTanggal"
              event-color="blue darken-3"
            ></v-date-picker
          ></v-dialog>
          <listnota
            v-if="idTab == 'Nota'"
            :nota="notas"
            :loading="loading"
            :transaksi="transaksi"
            @reload="reloadNota"
            :cekReload="cekReload"
            :toke="toke"
            :resetPosisi="reset"
            :pabrik="pabrik"
            :deo="deo"
          ></listnota>
          <notacampur
            v-if="idTab == 'Pencairan'"
            :nota="notaPencairan"
            :loading="loading"
            :transaksi="transaksi"
            @reload="reloadNota"
          >
          </notacampur>
          <modalan
            v-if="idTab == 'Modal'"
            :modal="modal"
            :idAkun="kasir"
            :tanggal="transaksi.tanggal"
            @reload="reloadFull"
          ></modalan>
          <tonase
            v-if="idTab == 'Tonase'"
            :toke="toke"
            :id="id"
            :deo="deo"
            :pabrik="pabrik"
            @reload="reloadFull"
          ></tonase>
          <pinjamanT
            v-if="idTab == 'Pinjaman'"
            :nota="transaksi"
            :toke="toke"
            total="0"
            :id="id"
            @reload="reloadFull"
          >
          </pinjamanT>
        </v-col>
        <rentang :pemicu="pemicuTgl" @tutup="tutupTgl" @simpan="simpanTanggal">
        </rentang>
        <printt
          @tutup="tutupPrint"
          :pemicu="pemicuPrint"
          :tanggalPrint="tanggalPrint"
          :admin="transaksi"
        ></printt>
        <log
          :pemicu="pemicuLog"
          :id="transaksi"
          @tutup="pemicuLog = false"
        ></log>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import listnota from "./listNota.vue";
import modalan from "./modalan.vue";
import tonase from "./tonase.vue";
import transaksibaru from "./input/transaksiBaru.vue";
import inputsaldo from "./input/inputsaldo.vue";
import pinjamanT from "./pinjaman.vue";
import rentang from "../component/popupTgl.vue";
import printt from "./portrait.vue";
import log from "./logPrint.vue";
import notacampur from "./notaCampur.vue";
import axios from "axios";
export default {
  components: {
    listnota,
    modalan,
    tonase,
    transaksibaru,
    inputsaldo,
    pinjamanT,
    rentang,
    printt,
    log,
    notacampur,
  },
  computed: {
    selisih() {
      return (
        parseInt(this.saldo.saldoIni) - parseInt(this.transaksi["saldo fakta"])
      );
    },
  },
  props: ["id", "kasir"],
  data() {
    return {
      idTransaksi: [],
      selectedRows: [],
      headers: [{ text: "tanggal", value: "tanggal" }],
      search: "",
      tab: null,
      items: ["Nota", "Modal", "Tonase", "Pinjaman"], // pencairan di banned sementara
      notas: [],
      nota: [],
      pinjaman: [],
      transaksi: {},
      loading: true,
      transaksiDialog: false,
      modal: [],
      skipKasir: false,
      idAkun: [],
      loadingIdtransaksi: false,
      cekReload: false,
      toke: [],
      deo: [],
      pabrik: [],
      idTab: "Nota",
      saldo: [],
      dialogSaldo: false,
      refreshSaldo: { akun: 0, keluar: 0, tanggal: "" },
      loadingRefresh: false,
      pemicuTgl: false,
      pemicuPrint: false,
      tanggalPrint: [],
      IDadmin: 0,
      halaman: 1,
      jumlahHalaman: 0,
      pemicuLog: false,
      tglTerpilih: "",
      listTanggal: [],
      notaPencairan: [],
      pemicuDate: false,
      reset: false,
    };
  },
  mounted() {
    this.loadData();
    this.selectedRows = [parseInt(this.id)];
  },
  watch: {
    tglTerpilih() {
      this.gantiLembar(this.tglTerpilih);
    },
    id() {
      this.getTransaksi();
      this.resetPosisi();
    },
  },
  methods: {
    getTransaksi() {
      this.loading = true;
      this.nota = [];
      this.transaksi = {};
      if (this.id == 0) {
        this.getKasir(this.kasir);
      } else {
        let uri = "/transaksi/" + this.id;
        axios
          .get(uri)
          .then((response) => {
            this.transaksi = response.data[0];
            if (this.skipKasir) {
              this.getNotas();
            } else {
              this.getKasir(this.transaksi.akun.id);
            }
          })
          .catch((e) => {
            this.$noty.error(e.message);
            this.loading = false;
          });
      }
    },
    getKasir(id) {
      this.loadingIdtransaksi = true;
      this.loading = true;
      this.idTransaksi = [];
      let uri = "/transaksi/" + id + "/kasir";
      axios
        .get(uri)
        .then((response) => {
          this.idTransaksi = response.data;
          this.listTanggal = this.idTransaksi.map((x) => {
            return x.tanggal;
          });
          this.tglTerpilih = this.transaksi.tanggal;
          this.getNotas();
          this.loadingIdtransaksi = false;
        })
        .catch((e) => {
          this.$noty.error(e.message);
          this.loading = false;
          this.loadingIdtransaksi = false;
        });
    },
    getNotas() {
      this.notas = [];
      let uri = `/transaksi/${this.id}/nota/rinci`;
      let n = [];
      axios
        .get(uri)
        .then((response) => {
          this.notas = response.data.data;
          n = JSON.parse(JSON.stringify(this.notas));
          console.log("nota awal:");
          console.log(this.notas);
          this.notas = this.notas.filter((x) => {
            return x.jenis == 1 || x.jenis == 0;
          });

          this.notas = this.notas.map((x) => {
            if (x.jenis == 1) {
              let id = x.ID;
              console.log(
                n.filter((y) => {
                  return y.id_nota == id;
                })
              );
              x.total += n.reduce((prev, itm) => {
                if (itm.id_nota == id) {
                  prev += parseFloat(itm.total);
                }
                return prev;
              }, 0);
            }
            return x;
          });
          console.log(this.notas);
          this.getNotaPencairan();
        })
        .catch((e) => {
          this.$noty.error(e.message);
          this.loading = false;
        });
    },
    getNotaPencairan() {
      this.notaPencairan = [];
      let uri = `/transaksi/${this.id}/notaPencairan`;
      axios
        .get(uri)
        .then((response) => {
          this.notaPencairan = response.data.data;
          this.getSaldo();
        })
        .catch((e) => {
          this.$noty.error(e.message);
          this.loading = false;
        });
    },
    getSaldo() {
      let uri = "/transaksi/" + this.id + "/saldo";
      axios
        .get(uri)
        .then((response) => {
          this.saldo = response.data;
          this.loading = false;
          this.cekReload = true;
        })
        .catch((e) => {
          this.$noty.error(e.message);
          this.loading = false;
        });
    },
    loadData() {
      this.getTransaksi();
    },
    refreshTotal() {
      let uri = "/transaksi/edit/" + this.id + "/totalcair";
      this.loadingRefresh = true;
      axios
        .get(uri)
        .then((response) => {
          if (response.data.status) {
            this.$noty.success("Data berhasil disimpan");
            this.loadingRefresh = false;
            this.updateSaldo();
            console.log(response.data);
          }
        })
        .catch((e) => {
          this.$noty.error(e.message);
          this.loading = false;
        });
    },
    kembali() {
      this.$router.push("/transaksilm");
    },
    lanjutan(item) {
      this.skipKasir = false;
      this.$router.push("/transaksilm/" + item.akun + "/" + item.id + "");
      this.transaksiDialog = false;
    },
    updateSaldo() {
      this.skipKasir = true;
      this.loadData();
    },
    tutupDialog() {
      this.transaksiDialog = false;
      this.dialogSaldo = false;
    },
    reloadNota() {
      this.cekReload = false;
      this.getNotas();
    },
    reloadFull() {
      this.skipKasir = false;
      this.loadData();
    },
    rowClicked(item) {
      this.skipKasir = true;
      this.$router.push("/transaksilm/" + this.kasir + "/" + item.id);
    },
    gantiLembar(tgl) {
      this.skipKasir = true;
      if (!this.idTransaksi === false) {
        if (this.idTransaksi.length > 0) {
          let id = this.idTransaksi.find((x) => {
            return x.tanggal == tgl;
          });
          if (!id === false) {
            console.log(id.id);
            if (!(this.id == id.id)) {
              this.$router.push("/transaksilm/" + this.kasir + "/" + id.id);
              this.pemicuDate = false;
            }
          }
        }
      }

      // this.$router.push("/transaksi/" + this.kasir + "/" + id);
    },
    tutupPrint() {
      this.pemicuPrint = false;
    },
    tutupTgl() {
      this.pemicuTgl = false;
    },
    simpanTanggal(x) {
      console.log("dipicu");
      console.log(x);
      this.tanggalPrint = x;
      this.IDadmin = this.transaksi.akun.id;
      let id = this.transaksi.akun.id;
      let tgl = this.transaksi.tanggal;
      let data = {
        akun: id,
        dari: x[0],
        sampai: x[1],
        tanggal: tgl,
        toke: "0",
        jenis: "1",
        uraian: null,
        akun_fee: "0",
      };
      let uri = "/print";
      axios
        .post(uri, data)
        .then((response) => {
          if (response.data.status) {
            this.$noty.success("Data berhasil disimpan");
            this.pemicuPrint = true;
          } else {
            this.$noty.success(response.data.data[0]);
          }
        })
        .catch((e) => {
          this.$noty.error(e.message);
          this.loading = false;
        });
    },
    resetPosisi() {
      this.reset = !this.reset;
    },
    printIn() {
      this.pemicuTgl = true;
    },
  },
};
</script>
