<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="400"
    persistent
  >
    <v-card dense tile>
      <v-card-title>Pinjaman</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col cols="12">
              <v-menu
                v-model="pupup"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="ubahTanggal"
                    clearable
                    label="Tanggal"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="date = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dataTransaksi.tanggal"
                  @change="pupup = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Harga"
                required
                :rules="[
                  (v) => v <= 10000000000 || 'Terlalu besar',
                  (v) => !isNaN(parseFloat(v)) || 'harus di isi angka',
                ]"
                v-model="dataTransaksi.harga"
                type="number"
                ref="harga"
              ></v-text-field
            ></v-col>
            <v-btn color="blue" dark type="submit" :disabled="loadingSimpan"
              >Simpan</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click="tutup()">Batal</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment";
import axios from "axios";
export default {
  props: ["pemicu", "pabrik"],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    ubahTanggal() {
      return this.dataTransaksi
        ? moment(this.dataTransaksi.tanggal).format("DD/MM/YYYY")
        : "";
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
      this.kondisikan();
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      dataTransaksi: {
        tanggal: "",
        pabrik: 0,
        harga: 0,
      },
      pupup: false,
      loading: false,
      loadingSimpan: false,
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kirim() {
      if (this.valid && this.loadingSimpan == false) {
        this.loadingSimpan = true;
        this.simpan();
        this.dataTransaksi.harga = parseInt(this.dataTransaksi.harga);
        let uri = "/harga";
        axios.post(uri, this.dataTransaksi).then((response) => {
          if (response.data.status) {
            this.$noty.success("Data berhasil disimpan");
            this.tutup();
            this.$emit("lanjutkan", response.data.data);
            console.log(response.data.data);
            this.loadingSimpan = false;
          }
        });
      }
    },
    inisialisasi() {
      //   this.$refs.harga.focus();
    },
    simpan() {},
    kondisikan() {
      this.dataTransaksi.tanggal = moment().format("YYYY-MM-DD");
      this.dataTransaksi.pabrik = this.pabrik;
      this.dataTransaksi.harga = 0;
    },
  },
};
</script>
