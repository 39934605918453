<template>
  <nav class="d-print-none">
    <v-app-bar
      dense
      dark
      color="indigo darken-1"
      fixed
      app
      src="https://picsum.photos/1000/100/?blur"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
        ></v-img>
      </template>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <span class="font-weight-light">SMJ</span><span>App</span>
      </v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute top temporary>
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <img src="/smj.jpg" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="text-uppercase">{{
              user ? user.name : ""
            }}</v-list-item-title>
            <v-list-item-subtitle>Logged In</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content class="red-text">
            <v-btn @click="logout"><v-icon>mdi-power</v-icon></v-btn>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" :to="item.route">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import axios from "axios";
export default {
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = `SMJApp - ${to.name}` || "SMJApp";
      },
    },
  },
  props: ["items", "user"],
  data() {
    return {
      drawer: false,
    };
  },
  computed: {},
  methods: {
    goto_toke(id) {
      return "/toke/" + id;
    },
    goto_url(nama) {
      console.log(nama);
      this.$router.push(nama);
    },

    logout() {
      axios
        .post("/logout")
        .then((response) => {
          this.$noty.success(response.data.pesan);
          localStorage.removeItem("token");
          this.$router.push("/login");
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
  },
};
</script>
