<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    persistent
    fullscreen
  >
    <v-card>
      <v-toolbar dense color="blue darken-4" dark elevation="-2">
        <v-toolbar-title>
          Rekap Pinjaman {{ idRekap.jenis }} periode
          {{ periode() }} </v-toolbar-title
        ><v-spacer></v-spacer>
        <span class="text-caption"
          >Data dibuat: {{ idRekap.tanggal_buat | formatDate }}</span
        ><v-btn icon color="red" @click="$emit('tutup')"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-toolbar>
      <v-row>
        <v-col cols="12">
          <div>
            <v-card>
              <v-card-title
                ><v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <v-btn @click="print" class="ma-2" color="pink" dark
                  ><v-icon>mdi-printer</v-icon></v-btn
                >
                <download-excel
                  :data="data"
                  :fields="fieldExcell"
                  worksheet="My Worksheet"
                  :name="filename"
                >
                  <v-btn clas="ma-2" color="green" dark
                    ><v-icon>mdi-table-large</v-icon></v-btn
                  >
                </download-excel>
              </v-card-title>
              <v-card-text>
                <v-data-table
                  dense
                  :headers="headers"
                  :items="data"
                  :search="search"
                  :items-per-page="10"
                  :loading="loading"
                  mobile-breakpoint="300"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon large class="mr-2" @click="buka(item)">
                      mdi-arrow-right
                    </v-icon>
                  </template>
                  <template v-slot:item.saldo_awal="{ item }">
                    {{ item.saldo_awal | rupiah }}
                  </template>
                  <template v-slot:item.pinjam="{ item }">
                    {{ item.pinjam | rupiah }}
                  </template>
                  <template v-slot:item.angsur="{ item }">
                    {{ item.angsur | rupiah }}
                  </template>
                  <template v-slot:item.saldo="{ item }">
                    {{ item.saldo | rupiah }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <div>
        <pinjaman
          :id="idTerpilih"
          :jenis="idRekap.jenis_pinjaman"
          @tutup="pemicuPinjaman = false"
          :dialogPinjaman="pemicuPinjaman"
        ></pinjaman>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import pinjaman from "../Toke/RinciPinjaman";
import axios from "axios";
export default {
  components: {
    pinjaman,
  },
  props: ["id", "pemicu"],
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Toke",
          align: "start",
          filterable: true,
          value: "toke.nama",
        },
        { text: "Regional", value: "toke.regional.regional" },
        { text: "Saldo Awal", value: "saldo_awal" },
        { text: "pinjam", value: "pinjam" },
        { text: "angsur", value: "angsur" },
        { text: "saldo", value: "saldo" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      loading: true,
      fieldExcell: {
        Nama: "toke.nama",
        Regional: "toke.regional.regional",
        "Saldo Awal": "saldo_awal",
        pinjam: "pinjam",
        angsur: "angsur",
        saldo: "saldo",
      },
      filename: "",
      idRekap: {},
      jenis: "PS",
      data: [],
      pemicuPinjaman: false,
      idTerpilih: 0,
    };
  },
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      if (this.pemicu) {
        this.inisialisasi();
      }
    },
  },
  mounted() {},
  methods: {
    async inisialisasi() {
      this.loading = true;
      const uri = "/rekap/pinjaman/" + this.id;
      const data = await axios.get(uri).then((res) => res.data);
      this.data = data.data;
      this.idRekap = data.tanggal_rekap;
      this.loading = false;
      console.log(this.data);
    },
    periode() {
      if (this.idRekap) {
        if (this.idRekap.jenis == 0) {
          return moment(this.idRekap.tanggal_A).format("MMMM YYYY");
        } else {
          return this.idRekap.tanggal_A + " - " + this.idRekap.tanggal_B;
        }
      }
    },
    buka(item) {
      this.idTerpilih = item.id_toke;
      this.pemicuPinjaman = true;
    },
    print() {
      let uri = "/print/pinjaman/" + this.idRekap.id;
      console.log(uri);
      const { href } = this.$router.resolve({
        path: uri,
      });
      window.open(href, "_blank");
    },
  },
};
</script>
