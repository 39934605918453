<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="dialog.value = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title
                >{{ tokeTerpilih.nama }} | <b>{{ jenis }}</b></v-toolbar-title
              ><v-spacer></v-spacer
              ><v-btn dark color="pink" @click="print" :disabled="!mode"
                >Print</v-btn
              >
            </v-toolbar>
            <div>
              <tabel-pinjaman
                v-if="mode === true"
                :tabel="tabel"
                saldoAwal="0"
                :loading="loading"
                :toke="toke"
                @reload="klik"
                :mode="mode"
              ></tabel-pinjaman>
              <tabel-print
                v-if="mode === false"
                :tabel="tabel"
                saldoAwal="0"
                :loading="loading"
                :toke="tokeTerpilih"
                :tanggalFilter="tanggalFilter"
                @reload="klik"
                @tutup="mode = true"
                :mode="mode"
              ></tabel-print>
            </div>
            <print
              :pemicu="pemicuPrint"
              @tutup="tutup"
              @simpan="printkan"
              :id="id"
            ></print>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import TabelPinjaman from "./TabelPinjaman.vue";
import TabelPrint from "./tabelPrint.vue";
import print from "../component/popupTgl.vue";
import axios from "axios";
export default {
  components: { TabelPinjaman, print, TabelPrint },
  props: {
    jenis: String,
    id: Number,
    dialogPinjaman: Boolean,
  },

  computed: {
    dialog: {
      get() {
        return this.dialogPinjaman;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  data() {
    return {
      tabel: [],
      mode: true,
      saldo: 0,
      loading: false,
      toke: [],
      tokeTerpilih: {},
      pemicuPrint: false,
      tanggalFilter: [],
    };
  },
  watch: {
    dialogPinjaman() {
      this.inisialisasi();
      this.mode = true;
    },
  },
  methods: {
    inisialisasi() {
      this.loading = true;
      axios
        .get("/toke/" + this.id + "/pinjaman/" + this.jenis)
        .then((response) => {
          this.tabel = response.data;
          this.loading = false;
          this.getToke();
        });
    },
    getToke() {
      let uri = "/tokeAktif";
      axios.get(uri).then((response) => {
        this.toke = response.data;
        this.tokeTerpilih = this.toke.find((x) => x.ID == this.id);
      });
    },
    klik() {
      this.inisialisasi();
    },
    print() {
      this.pemicuPrint = true;
    },
    printkan(item) {
      this.mode = false;
      this.tanggalFilter = item;
    },
    tutup() {
      this.pemicuPrint = false;
    },
  },
};
</script>
