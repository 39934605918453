<template>
	<v-container class="fill-height" fluid>
		<v-row align="center" justify="center">
			<v-col cols="12" sm="8" md="4">
				<v-card class="elevation-12"
					><v-toolbar color="blue darken-1" dark
						><v-toolbar-title>Login</v-toolbar-title></v-toolbar
					>
					<v-card-text>
						<v-form @submit.prevent="kirim()" ref="form" v-model="valid">
							<v-text-field
								label="User"
								name="login"
								prepend-icon="mdi-account"
								v-model="user.email"
								:error-messages="errors.email"
								type="text">
							</v-text-field>
							<v-text-field
								id="password"
								label="Password"
								name="login"
								v-model="user.password"
								prepend-icon="mdi-lock"
								:error-messages="errors.password"
								type="password">
							</v-text-field
							><v-btn color="blue" dark type="submit" :disabled="loadingSimpan"
								>Login</v-btn
							></v-form
						></v-card-text
					></v-card
				>
			</v-col>
		</v-row></v-container
	>
</template>
<script>
	import axios from "axios";
	export default {
		computed: {},
		watch: {
			pemicu() {
				this.inisialisasi();
				this.kondisikan();
			},
		},
		mounted() {},
		data() {
			return {
				valid: false,
				user: {
					email: "",
					password: "",
					device_name: "browser",
				},
				pupup: false,
				loading: false,
				loadingSimpan: false,
				errors: {},
			};
		},
		methods: {
			tutup() {
				this.$emit("tutup");
			},
			kirim() {
				this.errors = {};
				this.loadingSimpan = true;
				console.log("login");
				let uri = "/login";
				axios
					.post(uri, this.user)
					.then((response) => {
						if (response.data.status) {
							localStorage.setItem("token", response.data.data);
							this.$noty.success("Berhasil login");
							this.$router.push("/");
						} else {
							this.$noty.error(response.data.data);
						}
					})
					.catch((errors) => {
						console.log(errors);
						this.$noty.error(errors.message);
						// this.$noty.error("gagal login");
						this.errors = errors.response.data.errors;
					})
					.finally(() => {
						this.loadingSimpan = false;
					});
			},
			inisialisasi() {
				//   this.$refs.harga.focus();
			},
			kondisikan() {},
		},
	};
</script>
