<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="350"
    persistent
  >
    <v-card>
      <v-card-title class="headline"> Pilih Filter </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-row dense>
                <v-col cols="12">
                  <v-autocomplete
                    label="Akun"
                    v-model="akunPilihan"
                    :items="akun"
                    item-value="id"
                    item-text="nama"
                    multiple
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    label="Pabrik"
                    v-model="pabrikPilihan"
                    :items="pabrik"
                    item-text="nama"
                    item-value="id"
                    multiple
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete
                    label="Toke"
                    v-model="tokePilihan"
                    :items="toke"
                    item-value="id"
                    item-text="nama"
                    multiple
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn color="pink" dark @click="reset"> Reset </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="simpan"> Filter </v-btn>
        <v-btn color="warning" text @click="tutup"> Batal </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      loading: false,
      tokePilihan: [],
      akunPilihan: [],
      pabrikPilihan: [],
      toke: [],
      akun: [],
      pabrik: [],
      tonUpdate: [],
    };
  },
  props: ["pemicu", "tonase"],
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
    },
  },
  methods: {
    inisialisasi() {
      this.tonUpdate = this.tonase;
      this.toke = this.tabelFilter1("toke", "ID", "nama");
      this.akun = this.tabelFilter2("transaksi", "akun", "id", "nama");
      this.pabrik = this.tabelFilter2("do", "pabrik", "id", "pabrik");
    },
    tabelFilter1(kolom, idnya, nama) {
      if (this.tonUpdate.length >> 0) {
        var c = this.tonUpdate.reduce(function (chace, item) {
          const properti = item[kolom][idnya];
          const kolomA = item[kolom][nama];
          if (chace.some((el) => el.id === properti)) {
            return [...chace];
          }
          chace.push({ id: properti, nama: kolomA });
          return [...chace];
        }, []);
        return c;
      }
    },
    tabelFilter2(kolom, subKolom, idnya, nama) {
      if (this.tonUpdate.length >> 0) {
        var c = this.tonUpdate.reduce(function (chace, item) {
          const properti = item[kolom][subKolom][idnya];
          const kolomA = item[kolom][subKolom][nama];
          if (chace.some((el) => el.id === properti)) {
            return [...chace];
          }
          chace.push({ id: properti, nama: kolomA });
          return [...chace];
        }, []);
        return c;
      }
    },
    filterTh() {
      if (this.tonUpdate.length >> 0) {
        var c = this.tonUpdate.reduce(function (chace, item) {
          const properti = parseInt(item.th);
          if (chace.indexOf(properti) !== -1) {
            return [...chace];
          }
          chace.push(properti);
          return [...chace];
        }, []);
        return c;
      }
    },
    filter() {
      if (this.tonase.length >> 0) {
        this.tonUpdate = this.tonase;
        const toke = this.tokePilihan;
        const akun = this.akunPilihan;
        const pabrik = this.pabrikPilihan;
        if (toke.length >> 0) {
          console.log("toke difilter");
          this.tonUpdate = this.tonUpdate.filter(function (x) {
            return toke.indexOf(x.toke.ID) !== -1;
          });
        }
        if (akun.length >> 0) {
          console.log("akun di filter");
          this.tonUpdate = this.tonUpdate.filter(function (x) {
            return akun.indexOf(x["transaksi"]["akun"]["id"]) !== -1;
          });
        }
        if (pabrik.length >> 0) {
          this.tonUpdate = this.tonUpdate.filter(function (x) {
            return pabrik.indexOf(x["do"]["pabrik"]["id"]) !== -1;
          });
        }
      }
    },
    simpan() {
      this.filter();
      this.$emit("simpan", this.tonUpdate);
      this.$emit("tutup");
    },
    tutup() {
      this.$emit("tutup");
    },
    reset() {
      this.tokePilihan = [];
      this.pabrikPilihan = [];
      this.akunPilihan = [];
      this.$emit("reset");
      this.inisialisasi();
    },
  },
};
</script>
