<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="400"
    persistent
  >
    <v-card dense tile :loading="loading">
      <v-card-title>Pilih Pabrik dan DO</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="pabrikId"
                :items="pabrik"
                item-text="pabrik"
                item-value="id"
                label="Pabrik"
                ref="toke"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="deoId"
                :items="tbDeo"
                item-text="do"
                item-value="id"
                label="DO"
                ref="deo"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete>
            </v-col>
            <v-btn color="blue" dark type="submit" :disabled="loading"
              >Simpan</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click="tutup()">Batal</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment";
import axios from "axios";
export default {
  props: ["dialogDeo", "terpilih"],
  computed: {
    dialog: {
      get() {
        return this.dialogDeo;
      },
      set() {
        this.$emit("tutup");
      },
    },
    tbDeo: {
      get() {
        let idnya = this.pabrikId;
        let hasil = this.deo.filter((item) => {
          return item.pabrik.id == idnya;
        });
        return hasil ? hasil : ["pilih pabrik dulu"];
      },
      set() {},
    },
  },
  watch: {
    dialogDeo() {
      this.inisialisasi();
    },
    pabrikId() {
      this.deoId = this.tbDeo[0].id;
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      pabrikId: 0,
      deoId: 0,
      loading: false,
      harga: [],
      loadingHarga: true,
      tbUpdate: [],
      deo: [],
      pabrik: [],
    };
  },
  methods: {
    inisialisasi() {
      this.loading = true;
      let uri = "/pabrik";
      axios.get(uri).then((res) => {
        this.pabrik = res.data;
        this.getDeo();
      });
    },
    getDeo() {
      let uri = "/deo";
      axios.get(uri).then((res) => {
        this.deo = res.data;
        if (this.terpilih) {
          this.getHarga();
        }
        this.kondisikan();
        this.loading = false;
      });
    },
    tutup() {
      this.$emit("tutup");
    },
    kirim() {
      this.simpan();
      this.loadingHarga = true;
      let uri = "/tonase/gantideo/1";
      console.log(this.tbUpdate);
      axios
        .put(uri, this.tbUpdate)
        .then((response) => {
          if (response.data) {
            this.$noty.success("data Berhasil di Update");
            this.loadingHarga = false;
            this.$emit("update", this.pabrikId);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$noty.error(error);
        })
        .finally((this.loadingHarga = false));
      //   https://packagist.org/packages/mavinoo/laravel-batch <cari di sini, bisa untuk update bareng></cari>
    },
    simpan() {
      this.terpilih.forEach((item) => {
        this.tbUpdate.push({
          id: item.id,
          do: this.deoId,
          harga: this.cariHarga(item.tanggaldo).harga,
          id_harga: this.cariHarga(item.tanggaldo).id,
          tanggaldo: item.tanggaldo,
        });
      });
    },
    kondisikan() {
      this.pabrikId = 1;
      this.deoId = this.tbDeo[0].id;
      this.tbUpdate = [];
    },
    cariHarga(tgl) {
      if (this.loadingHarga == false) {
        let pabrik = this.pabrikId;
        console.log("pabrik");
        console.log(pabrik);
        let hasil = this.harga.filter(function (item) {
          return (
            moment(tgl).isSameOrAfter(item.tanggal) && pabrik == item.pabrik
          );
        });
        console.log(hasil);
        if (hasil.length > 0) {
          return hasil[hasil.length - 1];
        } else {
          return 0;
        }
      }
    },
    getHarga() {
      if (this.terpilih.length > 0) {
        let tgl = this.terpilih.sort((a, b) => {
          return new Date(b.tanggaldo) - new Date(a.tanggaldo);
        })[0].tanggaldo;
        let uri = "/harga/60hari/" + moment(tgl).format("YYYY-MM-DD");
        axios.get(uri).then((response) => {
          if (response.data) {
            this.harga = response.data;
            this.loadingHarga = false;
          } else {
            console.log("kesalahan uri:" + uri);
          }
        });
      }
    },
  },
};
</script>
