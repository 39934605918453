<template>
	<v-row class="ma-2"
		><v-col cols="12" md="4">
			<v-card class="elevation-1">
				<v-card-title>
					<v-text-field
						v-model="search"
						append-icon="mdi-magnify"
						label="Search"
						single-line
						hide-details></v-text-field>
				</v-card-title>
				<v-data-table
					:headers="judulPabrik"
					:items="pabrik"
					:items-per-page="10"
					:loading="loadingPabrik"
					v-model="pabrikTerpilih"
					:search="search"
					dense>
					<template v-slot:item="{ item }">
						<tr
							:class="
								pabrikTerpilih.indexOf(item.id) > -1
									? 'primary  white--text font-weight-bold'
									: ''
							"
							@click="rowClicked(item)">
							<td>{{ item.pabrik }}</td>
						</tr>
					</template>
				</v-data-table>
			</v-card> </v-col
		><v-col cols="12" md="8">
			<v-card color="pink lighten-1"
				><v-toolbar dense color="pink" dark>
					<v-toolbar-title>Masukkan Harga</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-btn icon @click="baru">
						<v-icon>mdi-plus-thick</v-icon>
					</v-btn>
				</v-toolbar>
				<v-data-table
					:headers="judulHarga"
					:items="harga"
					:page="lastPage"
					:items-per-page="12"
					:loading="loadingHarga"
					v-model="hargaTerpilih"
					class="elevation-1"
					dense
					:footer-props="{
						itemsPerPageText: 'tampilkan',
					}">
					<template v-slot:item.tanggal="{ item }">
						<span>{{ item.tanggal | formatDate }}</span>
					</template>
					<template v-slot:item.opsi="{ item }">
						<v-btn
							class="mx-2"
							icon
							color="red"
							x-small
							@click="hapus(item)"
							:disabled="loadingHapus"
							><v-icon> mdi-trash-can </v-icon></v-btn
						>
					</template>
				</v-data-table>
			</v-card>
			<r-new
				:pabrik="pabrikTerpilih[0]"
				:pemicu="pemicu"
				@tutup="tutup"
				@lanjutkan="lanjutkan"></r-new>
			<v-col cols="12"> </v-col>
		</v-col>
	</v-row>
</template>

<script>
	import rNew from "./newlama.vue";
	import _ from "lodash";
	import axios from "axios";
	export default {
		components: { rNew },
		computed: {
			lastPage() {
				return Math.ceil(this.harga.length / 12);
			},
		},
		data() {
			return {
				search: "",
				pabrik: [],
				loadingPabrik: false,
				pabrikTerpilih: [],
				harga: [],
				loadingHarga: false,
				hargaTerpilih: [],
				loadingHapus: false,
				judulPabrik: [
					{
						text: "Nama Pabrik",
						align: "start",
						filterable: true,
						value: "pabrik",
					},
				],
				judulHarga: [
					{
						text: "Tanggal",
						value: "tanggal",
					},
					{
						text: "Harga",
						value: "harga",
					},
					{
						text: "Opsi",
						value: "opsi",
					},
				],
				pemicu: false,
			};
		},
		mounted() {
			this.inisialisasi();
		},
		watch: {},
		methods: {
			inisialisasi() {
				this.loading = true;
				let url = "/pabrik";
				axios.get(url).then((response) => {
					this.pabrik = _.values(response.data);
					console.log(this.pabrik);
					this.loadingPabrik = false;
				});
			},
			tampilHarga() {
				this.loadingHarga = true;
				let url = "/harga/" + this.pabrikTerpilih[0] + "/pabrik";
				axios.get(url).then((response) => {
					this.harga = _.values(response.data);
					this.loadingHarga = false;
				});
			},
			rowClicked(item) {
				this.pabrikTerpilih = [item.id];
				this.tampilHarga();
			},
			hapus(item) {
				this.loadingHapus = true;
				let uri = "/harga/" + item.id;
				axios.delete(uri).then((response) => {
					this.$noty.success(response.data);
					this.loadingHapus = false;
					this.tampilHarga();
				});
			},
			lanjutkan() {
				this.tampilHarga();
			},
			tutup() {
				this.pemicu = false;
			},
			baru() {
				if (this.pabrikTerpilih[0] >> 0) {
					this.pemicu = true;
				} else {
					this.$noty.error("Pilih pabrik dulu");
					console.log("pabrik haurs di pilih");
					console.log(this.pabrikTerpilih);
				}
			},
		},
	};
</script>
