<template>
  <v-card
    ><v-toolbar dense color="blue darken-2" dark>
      <v-toolbar-title>Invoice</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn-toggle v-model="buku" mandatory tile color="accent-3" group>
        <v-btn value="2"> SMJ </v-btn>
        <v-btn value="3"> AKD </v-btn>
        <v-btn value="4"> JSAJ </v-btn>
        <v-btn value="5"> Non PT </v-btn>
      </v-btn-toggle>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="3">
          <bln @ubahBulan="ubahBulan"></bln>
        </v-col>
        <v-col cols="12" md="9">
          <v-card color="pink lighten-1"
            ><v-toolbar dense color="pink" dark>
              <v-toolbar-title>Daftar Invoice</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click="
                  id = 0;
                  pemicu = true;
                "
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
            </v-toolbar>
            <v-data-table
              :headers="judul"
              :items="invoice"
              :items-per-page="8"
              :loading="loading"
              v-model="terpilih"
              class="elevation-1"
              dense
              :footer-props="{
                itemsPerPageText: 'tampilkan',
              }"
            >
              <!-- <template v-slot:item.tanggal="{ item }">
            <span>{{ item.tanggal | formatDate }}</span>
          </template> -->
              <template v-slot:item.tanggalK="{ item }">
                {{ item["tanggal kirim"] | formatDate }}
              </template>
              <template v-slot:item.tanggalI="{ item }">
                {{ item["tanggal inv"] | formatDate }}
              </template>
              <template v-slot:item.dpp="{ item }">
                {{ item["dpp"] | rupiah }}
              </template>
              <template v-slot:item.ppn="{ item }">
                {{ item["ppn"] | rupiah }}
              </template>
              <template v-slot:item.pph="{ item }">
                {{ item["pph"] | rupiah }}
              </template>
              <template v-slot:item.ppnF="{ item }">
                {{ item["PPN FAKTA"] | rupiah }}
              </template>
              <template v-slot:item.opsi="{ item }">
                <v-btn
                  class="mx-1"
                  icon
                  x-small
                  color="green"
                  @click="
                    idTon = item.id;
                    pemicuTon = true;
                  "
                  ><v-icon> mdi-open-in-new </v-icon></v-btn
                >
                <v-btn
                  class="mx-1"
                  icon
                  x-small
                  color="green"
                  @click="
                    id = item.id;
                    pemicu = true;
                  "
                  :disabled="loadingHapus"
                  ><v-icon> mdi-pencil </v-icon></v-btn
                >
                <v-btn
                  class="mx-1"
                  icon
                  color="red"
                  x-small
                  @click="hapus(item)"
                  :disabled="loadingHapus"
                  ><v-icon> mdi-trash-can </v-icon></v-btn
                >
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <invoice
        :id="id"
        :buku="buku"
        :bln="bulan"
        @tutup="pemicu = false"
        @lanjutkan="inisialisasi"
        :pemicu="pemicu"
      ></invoice>
      <tonase
        :pemicu="pemicuTon"
        :id="idTon"
        @tutup="pemicuTon = false"
        @lanjutkan="inisialisasi"
      ></tonase>
    </v-card-text>
  </v-card>
</template>
<script>
import bln from "./bln.vue";
import axios from "axios";
import _ from "lodash";
import invoice from "./invoice.vue";
import tonase from "./tonase.vue";
export default {
  components: { bln, invoice, tonase },
  data() {
    return {
      judul: [
        {
          text: "No",
          value: "no inv",
        },
        {
          text: "Pabrik",
          value: "pabrik.pabrik",
        },
        {
          text: "Tanggal Kirim",
          value: "tanggalK",
        },
        {
          text: "Tanggal Invoice",
          value: "tanggalI",
        },
        {
          text: "DPP",
          value: "dpp",
        },
        {
          text: "PPN",
          value: "ppn",
        },
        {
          text: "PPN Fakta",
          value: "ppnF",
        },
        {
          text: "PPH",
          value: "pph",
        },
        {
          text: "opsi",
          value: "opsi",
          width: "120",
        },
      ],
      invoice: [],
      loading: false,
      terpilih: [],
      loadingHapus: false,
      buku: 2,
      bulan: "",
      pemicu: false,
      id: 0,
      idTon: 0,
      pemicuTon: false,
    };
  },
  watch: {
    buku() {
      this.inisialisasi();
    },
  },
  mounted() {
    this.inisialisasi();
  },
  methods: {
    ubahBulan(item) {
      this.bulan = item.replace("-", "/");
      console.log(this.bulan);
      this.inisialisasi();
    },
    inisialisasi() {
      let url = "";
      let tgl = new Date();
      let bln = tgl.getMonth() + 1;
      let thn = tgl.getFullYear();
      this.loading = true;
      if (this.bulan == "") {
        this.bulan = thn + "/" + bln;
        console.log(this.bulan);
      }
      console.log(this.bulan);
      url = `/invoice/bulan/${this.bulan}/${this.buku}`;
      axios.get(url).then((response) => {
        this.invoice = _.values(response.data);
        console.log(this.invoice);
        this.loading = false;
      });
    },
    hapus(item) {
      this.loadingHapus = true;
      let uri = "/invoice/" + item.id;
      axios.delete(uri).then((response) => {
        this.$noty.success(response.data.pesan);
        this.loadingHapus = false;
        this.inisialisasi();
      });
    },
  },
};
</script>
