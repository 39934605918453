<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="400"
    persistent
  >
    <v-card dense tile :loading="loading">
      <v-card-title>Pilih Pabrik dan Toke</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="pengantar.toke"
                auto-select-first
                :items="toke"
                item-text="nama"
                item-value="ID"
                label="Toke"
                ref="toke"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="pengantar.pabrik"
                auto-select-first
                :items="pabrik"
                item-text="pabrik"
                item-value="id"
                label="Pabrik"
                ref="toke"
                :rules="[(v) => !!v || 'Harus diisi']"
                autofocus
              >
              </v-autocomplete>
            </v-col>
            <v-btn
              color="blue"
              dark
              type="submit"
              :loading="loading"
              :disabled="loading"
              >Simpan</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click="tutup()">Batal</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: ["pengantarDialog", "nota"],
  computed: {
    dialog: {
      get() {
        return this.pengantarDialog;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pengantarDialog() {
      this.kondisikan();
    },
    dialog() {
      this.inisisalisasi();
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      pengantar: {
        toke: 0,
        do: 0,
        pabrik: 0,
        harga: [],
      },
      toke: [],
      deo: [],
      pabrik: [],
      loading: false,
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    inisisalisasi() {
      this.loading = true;
      let uri = "/tokeAktif";
      axios.get(uri).then((r) => {
        this.toke = r.data;
        this.getDeo();
      });
    },
    getDeo() {
      let uri = "/deo";
      axios.get(uri).then((r) => {
        this.deo = r.data;
        this.getPabrik();
      });
    },
    getPabrik() {
      let uri = "/pabrik";
      axios.get(uri).then((r) => {
        this.pabrik = r.data;
        this.loading = false;
      });
    },
    kirim() {
      let pb = this.pengantar.pabrik;
      let filterDeo = this.deo.filter((item) => {
        return item.pabrik.id == pb;
      });
      this.pengantar.do = filterDeo[0].id;
      let idP = this.pengantar.pabrik;
      this.pengantar.txPabrik = this.pabrik.find(function (item) {
        return item.id == idP;
      }).pabrik;
      this.getharga();
    },
    simpan() {},
    kondisikan() {
      this.pengantar.toke = this.nota.toke.ID;
    },
    getharga() {
      this.loading = true;
      let uri = "/harga/" + this.pengantar.pabrik + "/pabrik";
      axios.get(uri).then((response) => {
        this.pengantar.harga = response.data;
        this.$emit("lanjutkan", this.pengantar);
        this.loading = false;
      });
    },
  },
};
</script>
